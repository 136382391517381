import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import CN from 'classnames';
import { Button, Checkbox, ContextMenu, Icon, LoadingOverlay, SplitButton, StatusIcon, Tooltip } from 'Atoms';
import { MODALS, ModalManager } from 'Molecules';
import DesignEditor from './DesignEditor.jsx';
import { DesignItems, ExternalCostView } from './DesignItem';
import { 
  DesignApprovalButtons, DesignApprovals, DesignApprovalContextMenus, 
} from './DesignValidation.jsx';

import { toDesigns, requestMapper, toDesignStatus } from '../../QuoteViewerMapper.js';
import { cancelOrder, updateDesign, manuallyOrderQuote, approveDesign, rejectDesign, resetDesignApprovals } from 'Actions/ccm/api.js';
import { useAnalytics, useToggle } from 'Hooks';
import { getLabels, PERMISSIONS } from 'Utils/const.js';
import { toCurrency, toDesignApprovers, isExpiredIsoStringDate, formatDateNoTime, toProductPricingStatus } from 'Utils/formatter.js';

import './DesignRollup.scss';

const VIEW_ORDER_STATUSES = ['ordering', 'ordered'];
const ORDERED_STATUSES = ['ordered', 'manually-ordered'];
const READ_ONLY_STATUSES = ['ordered', 'expired'];
const CANCEL_STATUSES = ['design-validation'];

const DesignRollup = props => {
  // Props
  const { 
    domId, quoteOrgName, quoteRef, quoteCurrency,
    quoteId, quoteStatus, extendedDemarc, 
    design={}, designEditorId, orderingDisabled, 
    onCopy, onSave, onCancel,
    showMarginPct, toggleShowMarginPct,
    locationA, locationZ, orgRequiresOrderManager, user = {},
  } = props;

  const { 
    status, leadTime, designItems=[],
    displayCurrency = (quoteCurrency || 'USD'), 
    mrc, mrr, nrc, nrr, mrv, nrv, ug_mrr, ug_nrr, external_mrc, external_nrc,
    marginAmt, marginPct, commissionAmt, commissionPct, userMarginPct,
    productName = 'Unnamed Product', reference, connectivityVendors,
    bandwidth, upload, term, internetType, hasOrderDraft, hasExtendedDemarc,
    hasUnsentNotifications, validation, routeDistance, directDistance, productPricingStatus, messages=[],
  } = design;

  const isExpired = isExpiredIsoStringDate(design.expireDate);
  const allowManualOrder = user.is_pricing_desk && !READ_ONLY_STATUSES.includes(status);
  const MANUAL_ORDER_MENU_ID = `manual-order-menu_${design.id}`;

  const isMissingDemarc = () => (extendedDemarc && !hasExtendedDemarc);

  // State
  const [ isEditing, setIsEditing ] = useState(false);
  const [ isSolution, setIsSolution ] = useState(!!design.isSolution);
  const [ isFetching, setIsFetching ] = useState(false);
  const updatedDesign = useRef(design);

  const [ isExpanded, toggleIsExpanded, setIsExpanded ] = useToggle(props.isExpanded || ORDERED_STATUSES.includes(status) || false);

  const onClickManualOrder = () => {
    modalRef.current.open(MODALS.CONFIRMATION, {
      label: 'Confirm that this design has been manually ordered?',
      children: 'Doing so will indicate that an order for this design has already been created and that any other order or installation details will not be accessible from within CCM.',
      cancelText: 'No, cancel',
      confirmText: 'Yes, confirm',
      onConfirm: onClick.manuallyOrder,
    })
  }

  const manualOrderItems = user.is_pricing_desk && [
    { label: 'Manually Order', onClick: onClickManualOrder }
  ]

  // Hooks
  const history = useHistory();
  const { trackEvent } = useAnalytics();
  const modalRef = useRef();

  // UseEffect
  useEffect(function updateSolutionCheckbox() {
    setIsSolution(!!design.isSolution)
  }, [design.isSolution]);

  useEffect(function toggleExpanded() {
    props.isExpanded && !isExpanded && setIsExpanded(props.isExpanded);
  }, [props.isExpanded]);

  useEffect(function updateDesign() {
    if (design) {
      updatedDesign.current = design;
    } 
  }, [design]);

  const refreshQuote = () => {
    setIsFetching(true);
    window.location.reload();
  }

  const onChangeSolution = ({ currentTarget }) => {
    if (!!currentTarget.checked && isMissingDemarc()) {
      modalRef.current.open(MODALS.CONFIRMATION, {
        label: 'Add extended demarc?',
        children: 'This quote requires an extended demarc. Would you like to add an extended demarc and continue marking this design as a solution?',
        cancelText: 'No, cancel',
        confirmText: 'Yes, add demarc',
        onConfirm: onClick.addExtendedDemarc,
      })
    } else {
      setIsSolution(currentTarget.checked);
      setIsFetching(true);
      updateDesign({ 
        id: design.id, 
        is_solution: currentTarget.checked, 
        design_status: toDesignStatus(status),
        send_manual_design_email: false,
      }).then(resp => {
        onSave(resp.error 
          ? { error: 'Error updating the design.' } 
          : { design: toDesigns({ results: [resp] })[0] }
        );
        setIsFetching(false);
      });
     }
  };

  // Click handler
  const onClick = useRef({
    addExtendedDemarc: () => {
      const isAtLocation = (item, type, { locationA, locationZ }) => {
        return item.type === type && (
          (locationA && item.locationA && item.locationA.id === locationA.id) || 
          (locationZ && item.locationZ && item.locationZ.id === locationZ.id)
        )
      }

      const demarcAtLocation = (loc) => designItems.some(item => isAtLocation(item, 'extended-demarc', loc));

      const createDemarc = ({ locationA, locationZ, connection={} }) => {
        return {
          type: 'extended-demarc',
          location_a: (locationA || locationZ).id, 
          location_z: (locationA || locationZ).id,
          externalMrc: null,
          externalNrc: null,
          vendorUrl: user.organization, // assumes that user is Unitas user
          vendorProduct: 'Extended Demarc',
          option_selections: connection.option_selections,
        };
      }

      const addDemarc = (arr=[], loc) => {
        let position = 0;
        let connection;

        // If at locationA, then add BEFORE provider-connection
        if (loc.locationA) {
          position = items.findIndex(item => (isAtLocation(item, 'provider-connection', loc)))
          if (position > -1) {
            (connection = items[position]);
          } 
        }

        // If at locationZ, then add AFTER last provider-connection
        if (loc.locationZ) {
          for (let l = items.length-1; l > -1 && !position; l--) {
            isAtLocation(items[l], 'provider-connection', loc) && (position = l);
          }

          if (position > -1) {
            connection = items[position];
            position++;
          }
        }

        connection && arr.splice(position, 0, createDemarc({ ...loc, connection }));
      }

      const requiresDemarc = (loc) => {
        const l = loc.locationA || loc.locationZ;
        return (l && !l.data_center_url && (l.floor || l.suite) && !demarcAtLocation(loc));
      }

      let items = [...designItems];
      requiresDemarc({ locationA }) && addDemarc(items, { locationA });
      requiresDemarc({ locationZ }) && addDemarc(items, { locationZ });

      if (items.length > designItems.length) {
        setIsFetching(true);

        const request = {
          id: design.id, 
          is_solution: true, 
          design_status: toDesignStatus(status),
          design_items: requestMapper.toDesignItems({ locationA, locationZ, term: design.term, currency: displayCurrency, items }),
        };

        updateDesign(request).then(resp => {
          onSave(resp.error 
            ? { error: 'Error updating the design.' } 
            : { design: toDesigns({ results: [resp] })[0] }
          );
          setIsFetching(false);
        });
      }
    },

    order: () => {
      trackEvent({ category: 'Order Quote', action: 'click' });
      history.push(`/quote/${quoteRef}/order/${design.id}`);  
    },

    manuallyOrder: () => {
      trackEvent({ category: 'Order Quote Manually', action: 'click' });
      setIsFetching(true);
      manuallyOrderQuote(quoteId, design.id).then(({ error }) => {
        error 
          ? alert('Error setting quote to manually ordered')
          : refreshQuote()
      }).finally(() => setIsFetching(false));
    },

    edit: () => setIsEditing(true),

    cancel: () => {
      setIsEditing(false);
      onCancel?.({ designEditorId });
    },

    cancelOrder: () => {
      modalRef.current.open(MODALS.CANCEL_ORDER, {
        onConfirm: async (reason) => {
          setIsFetching(true);
          const { error } = await cancelOrder(quoteId, { designId: design.id, reason });
          if (error) {
            alert(`Error rejecting order: ${error}`);
            return setIsFetching(false);
          }
          refreshQuote();
        },
      })
    },

    copy: () => {
      setIsEditing(false);
      setIsExpanded(false);
      onCopy && onCopy(updatedDesign.current);
    },

    save: saveData => {
      setIsEditing(!!saveData.error);
      setIsExpanded(true);
      onSave && onSave(saveData);
    },

    approveDesign: async (approvalType) => {
      trackEvent({ category: 'Validate Quote Order', action: 'click' });
      setIsFetching(true);
      const { error } = await approveDesign(design.id, approvalType);
      if (error) {
        alert(`Error validating quote order: ${error}`);
        return setIsFetching(false);
      }
      refreshQuote();
    },

    rejectDesign: (rejectionType) => {
      const onConfirm = async (reason) => {
        setIsFetching(true);
        const { error } = await rejectDesign(design.id, reason, rejectionType);
        if (error) {
          alert(`Error rejecting order: ${error}`);
          return setIsFetching(false);
        }
        refreshQuote();
      }

      modalRef.current.open(MODALS.DESIGN_REJECTION, { onConfirm });
    },

    clearApprovals: () => {
      const onConfirm = async () => {
        const resetApprovals = Object.entries(validation)
          .filter(([, val]) => val?.status === 'approved')
          .map(([key,]) => key);

        const { error } = await resetDesignApprovals(design.id, resetApprovals);
        (error)
          ? alert('Error updating design approvals.')
          : refreshQuote();
      };

      return () => { 
        modalRef.current.open(MODALS.CONFIRMATION, { 
          label: 'Clear design validation approval',
          children: `If the design has been edited, it may require additional approval from other parties. Would you like to clear these approvals to have any new design changes reviewed?`,
          cancelText: 'No, cancel',
          confirmText: 'Yes, reset approvals',
          onConfirm 
        });
      }
    },

    clearRejection: (role) => {
      const onConfirm = async () => {
        const { error } = await resetDesignApprovals(design.id, [role]);
        (error)
          ? alert('Error updating design approvals.')
          : refreshQuote();
      };

      return () => { 
        modalRef.current.open(MODALS.CONFIRMATION, { 
          label: 'Clear design validation rejection',
          children: `If the design has been edited, it may need to be reviewed to satisfy the reasons that the designs was rejected. Would you like to clear this rejection to have this design reviewed?`,
          cancelText: 'No, cancel',
          confirmText: 'Yes, clear rejection',
          onConfirm 
        });
      }
    }
  }).current;

  // Render variables
  const isPricingDeskUser = !!user.is_pricing_desk;
  const commissionTooltip = user.is_internal && commissionPct && (
    `${commissionPct}% Agent Commission: ${toCurrency(commissionAmt, displayCurrency)}`
  );

  const uploadSpeed = !upload ? 0 : upload >= 1000 ? `${upload/1000} Gbps` : `${upload} Mbps`;
  const isOffnet = designItems.some(x => x.proximityA === 'Off-Net' || x.proximityZ === 'Off-Net');

  const isUnverifiedHub = designItems.some(x => 
    x.locationA?.data_center_facility_status === 'virtual-unverified' || 
    x.locationZ?.data_center_facility_status === 'virtual-unverified'
  );

  const className = CN('design-rollup', { 
    'design-rollup--expanded': isExpanded, 
    'design-rollup--solution': isSolution,
    'design-rollup--not-solution': !isSolution,
    'design-rollup--offnet': isOffnet,
    'design-rollup--validation': status === 'design-validation',
    'design-rollup--ordered': ORDERED_STATUSES.includes(status),
  });
  const designTitle = internetType || productName;
  const readOnly = READ_ONLY_STATUSES.includes(status);
  const tooltip = (typeof orderingDisabled === 'string' && orderingDisabled);
  const alwaysDisabled = !!tooltip || (!!orderingDisabled && !VIEW_ORDER_STATUSES.includes(status)); // allow users to view existing orders
  const orderDisabled = alwaysDisabled || (isExpired && status === 'quoted' && !hasOrderDraft && !isPricingDeskUser);
  const labels = getLabels(user);

  const showEnduserRate = !user.is_internal && (mrr !== mrv || nrr !== nrv);
  const mrcIsUgMrc = (ug_mrr || ug_nrr);

  const buttonText = (
    (status === 'ordering-pending-approval' || status === 'ordered') 
      ? 'View Order' :
    (hasOrderDraft) 
      ? 'Resume Order' :
    (isExpired)
      ? `Expired on ${formatDateNoTime(design.expireDate)}`
      : 'Order'
  );

  const statusIcon = (
    status === 'ordering-pending-approval'
      ? 'ordering-pending-approval' :
    (status === 'design-validation' || hasOrderDraft)
      ? 'design-validation' :
    status === 'manually-ordered'
      ? 'manually-ordered' : 
    status === 'ordered'
      ? 'ordered' :
    status === 'manual'
      ? 'edited'
      : null
  );

  const statusText = (
    (status === 'design-validation') 
      ? `Requires approval: ${toDesignApprovers(validation)}` : 
    (status === 'ordering-pending-approval')
      ? 'Pending DocuSign Approval' :
    hasOrderDraft
      ? `Resume Order` :
    (status === 'manual' && hasUnsentNotifications && isSolution && isPricingDeskUser)
      ? 'Edited •  (Unsent Changes)'
      : undefined
  );
  
  const snowUrl = user.is_internal && status === 'ordered' && `https://unitasglobal${
    window.location.hostname !== 'www.unitascloud.com' ? 'dev' : ''
  }.service-now.com/csm_order_list.do?sysparm_query=GOTO123TEXTQUERY321=${reference}`;

  const showingCancelOrder = CANCEL_STATUSES.includes(status) && user.hasPermission(PERMISSIONS.CANCEL_ORDER);

  // Render
  return (
    !isEditing ? (
      <div id={domId} className={className}>
        {!!isFetching && <LoadingOverlay />}
        {/* <div
          className="design-rollup__title-bar"
          style={{
            backgroundColor: "#00add8",
            padding: "0.25rem 0.5rem",
            fontSize: ".75rem",
            color: "#eee",
            display: 'none'
          }}
        >
          <div className="design-rollup__title-bar--ref">{`ID: ${reference}`}</div>
          <div className="design-rollup__title-bar--bandwidth">
            <Icon name="speed" color="#eee" />{bandwidth}
          </div>
          <div className="design-rollup__title-bar--port">
            <Icon name="port" color="#eee" />{port}
          </div>
          <div className="design-rollup__title-bar--term">                  
            <Icon name="calendar" color="#eee" />{term.replace('Months', 'Month Term')}
          </div>
        </div> 
      */}
        <div className="design-rollup__summary">

          <div className="checkbox-container">
            {!!isPricingDeskUser && (
              <Checkbox id={`chk-${reference}`} checked={isSolution} onChange={onChangeSolution} disabled={readOnly} title={isSolution ? 'Remove as quoted solution' : 'Show as quoted solution'} />
            )}

            <div className="design-rollup-description">
              <div className="design-rollup__reference">{`ID: ${reference}`}</div>
              <div className="design-title">{designTitle}</div>
              <div>
                {bandwidth}
                {!!uploadSpeed && (
                  <> <Icon name="arrow-down-large"/> {uploadSpeed} <Icon name="arrow-up-large" /></>
                )}
              </div>
              <div className="design-rollup__vendors">
                {!!user.is_developer && (
                  <DesignDistanceRatio direct={directDistance} route={routeDistance} />
                )}
                <Icon name="customer" />{connectivityVendors}
              </div>
              {statusIcon && (
                <StatusIcon status={statusIcon} text={statusText} className="design-rollup__status" />
              )}
              {(status === 'design-validation') && (
                <DesignApprovals validation={validation} onClick={onClick} />
              )}
              {isOffnet && (
                <div className="design-rollup-description__offnet-message">
                  <Icon className="error-icon" name="exclamation-point-circle-outline" />
                  <span>Off-Net Design</span>
                </div>
              )}
              {!!user.is_internal && isUnverifiedHub && (
                <div className="design-rollup-description__unverified-hub">
                  <Icon className="warning-icon" name="exclamation-point-triangle-filled" />
                  <span>Using Unverified Hub</span>
              </div>
              )}
              {!!messages?.length && (
              <>
                {messages.map((msg, i) => (
                  <div className="design-rollup-description__unverified-hub" key={i}>
                    <Icon className="warning-icon" name="exclamation-point-triangle-filled" />
                    <span>{msg}</span>
                  </div>
                ))}
              </>
              )}
              {extendedDemarc && (
                <div className={CN('demarc-message', { 
                  'demarc-message--mpoe': !hasExtendedDemarc,
                  'demarc-message--demarc': !!hasExtendedDemarc
                })}>
                { hasExtendedDemarc
                  ? <><Icon className="success-icon" name="checkmark-circle-outline" /><span>Includes extended demarc</span></>
                  : <><Icon className="warning-icon" name="exclamation-point-triangle-filled" /><span>MPOE</span></>
                }
                </div>
              )}
            </div>
          </div>
          <div className="design-rollup__summary__rows">
            <div className="design-rollup__summary__row">

              <div className={CN("monthly-pricing", { 'monthly-pricing--enduser-rates': !!showEnduserRate })}>
                {/* MRC / UNITAS MRC */}
                { !isNaN(mrc) && 
                  <LabelValue label={labels.mrc} amount={mrc} currency={displayCurrency} />
                }
                {/* UNITAS MRR - Only displayed to internal users */}
                { !isNaN(ug_mrr) && 
                  <LabelValue 
                    label={<>
                      {!!commissionTooltip && <Tooltip title={commissionTooltip} children={<b><Icon name="other-charges" /></b>} /> }
                      {labels.ug_mrr}
                    </>
                    } amount={mrr} currency={displayCurrency} />
                }
                {/* MRR */}
                { mrcIsUgMrc && !!showEnduserRate &&
                  <LabelValue label={labels.mrr} amount={mrv} currency={displayCurrency} />
                }
                { !mrcIsUgMrc && !isNaN(mrr) && 
                  <LabelValue label={labels.mrr} amount={mrr} currency={displayCurrency} />
                }
              </div>

              <div className={CN("install-pricing", { 'install-pricing--enduser-rates': !!showEnduserRate })}>
                { !isNaN(nrc) && 
                  <LabelValue label={labels.nrc} amount={nrc} currency={displayCurrency} />
                }
                { !isNaN(ug_nrr) && 
                  <LabelValue label={labels.ug_nrr} amount={nrr} currency={displayCurrency} />
                }
                { mrcIsUgMrc && !!showEnduserRate &&
                  <LabelValue label={labels.nrr} amount={nrv} currency={displayCurrency} />
                }
                { !mrcIsUgMrc && !isNaN(mrr) && 
                  <LabelValue label={labels.nrr} amount={nrr} currency={displayCurrency} />
                }
              </div>

              <div className="design-actions">
                {!!showingCancelOrder && (
                  <Button secondary onClick={onClick.cancelOrder} children="Cancel Order" />
                )}
                { !!isPricingDeskUser && status !== 'ordered' && (
                  <>
                    <Button secondary onClick={onClick.edit}>Edit</Button>
                    <Button secondary onClick={onClick.copy}>Copy</Button>
                  </>
                )}
                {(status !== 'manually-ordered') && (
                  <Tooltip title={tooltip}>
                    <div className="flex-row">
                      {(status === 'design-validation') 
                        ? <DesignApprovalButtons validation={validation} onClick={onClick} isFetching={isFetching} designId={design.id} /> :
                      (snowUrl) ? (
                        <>
                          <Button primary onClick={onClick.order} children="View CCM Order" />
                          <a href={snowUrl} target="_blank" className="button primary">View SNOW Order</a>
                        </> 
                      ) :
                      (allowManualOrder)
                        ? <SplitButton primary onClick={onClick.order} disabled={orderDisabled} children={buttonText} menuId={MANUAL_ORDER_MENU_ID} />
                        : <Button primary onClick={onClick.order} disabled={orderDisabled} children={buttonText} />
                      }
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
            {!isExpanded && (
              <div style={{ textAlign: 'right', alignItems: 'center', display: 'flex', justifyContent: 'flex-end' }}>
                <div className="design-rollup__contract-term" style={{ paddingRight: '1rem'}}>
                  <Icon name="quote" /><span title={productPricingStatus}>{toProductPricingStatus(productPricingStatus)}</span>
                </div>
                {!!user.is_internal && !!marginPct && (
                  <div className="design-rollup__contract-term" onClick={toggleShowMarginPct} style={{ paddingRight: '1rem', cursor: 'pointer' }}>
                    <Icon name="other-charges" />Margin {showMarginPct ? `${Number(marginPct)?.toFixed(0)}%` : toCurrency(marginAmt, displayCurrency)}
                  </div>
                )}
                <div className="design-rollup__contract-term">
                  <Icon name="calendar" />{term.replace('Months', 'Month Term')}
                </div>
                {/* {!!user.is_internal && !!(external_mrc || external_nrc) && (
                  <div className="design-rollup__external-costs">
                    <ExternalCostView currency={displayCurrency} externalMrc={external_mrc} externalNrc={external_nrc} quoteOrgName={quoteOrgName} isEditable={false} />
                  </div>
                )} */}
              </div>
            )}
          </div>
          
          <button onClick={toggleIsExpanded} className="toggle-legs-button">
            <Icon name="caret-down" />
          </button>
        </div>

        <div className="design-container" hidden={!isExpanded}>
          <DesignItems {...props} />
        </div>
        <ModalManager ref={modalRef} />
        {allowManualOrder && (
          <ContextMenu id={MANUAL_ORDER_MENU_ID} items={manualOrderItems} className="design-rollup__manual-order-context-menu" position="auto" />
        )}
        {(status === 'design-validation') && (
          <DesignApprovalContextMenus validation={validation} designId={design.id} onClick={onClick} />
        )}
      </div>
    ) : (
      <DesignEditor {...props} onSave={onClick.save} onCancel={onClick.cancel} onToggleIsSolution={setIsSolution} />
    )
  );
}

const LabelValue = ({ label, amount, currency }) => (
  <div className="label-value">
    <div className="label-value__label">{label}</div>
    <div className="label-value__value">{toCurrency(amount, currency)}</div>
  </div>
);

const DesignDistanceRatio = ({ route, direct }) => {
  const ratio = direct ? (route / direct).toFixed(1) : '?';
  const title = `Est. total (${Math.round(route)}) / direct (${ direct ? Math.round(direct) : '?'})`;

  return (
    <Tooltip title={title}>               
      <div className="design-rollup__design-distance">
        <Icon name="route" />
        <div className="design-rollup__design-distance-ratio">{ratio}</div>
      </div>
    </Tooltip>
  );
}

export default DesignRollup;
