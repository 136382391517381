import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { upsertDesign } from 'Actions/ccm/api.js';
import { toAddress } from 'Utils/formatter.js';

import { requestMapper, toDesigns }  from 'Pages/CCM/QuoteView/QuoteViewerMapper.js';
import { getDesignEditorOptions, EDITOR_MODALS } from './DesignEditorHelper.js';

import useDesignPricing from './useDesignPricing';
import useDesignItemsManager from './useDesignItemsManager';
import useDesignValidator from './useDesignValidator';

const useDesignEditor = (props) => {
  const { design, designEditorId, specification, partnerType, partnerId, quoteOrgId, quoteOwnerId, quoteCurrency, onToggleIsSolution, onSave, onCancel } = props;
  const options = useRef(getDesignEditorOptions(design.designType)).current;

  // useRef - Default values for lookup options
  const defaultValues = useRef({
    term: options.term.find(({ label }) => label === design.term)
      || options.term.find(({ value }) => value === props.term[0]),
    leadTime: options.leadTime.find(
      ({ value }) => value === design.leadTime) 
      || (design.leadTime && { value: design.leadTime, label: design.leadTime })
      || undefined,
    productPricingStatus: options.productPricingStatus.find(({ value }) => value === design.productPricingStatus?.toLowerCase()) || options.productPricingStatus[0],
    designType: options.designType.find(({ value }) => value === design.designType),
    locationZ: props.locationZ || design.locationZ,
  }).current;
  
  // useState - Design Props
  const intialLocationZ = (design.locationZ || props.locationZ);

  const [ locationZ, setLocationZ ] = useState(intialLocationZ);
  const [ isSolution, setIsSolution ] = useState(design.isSolution);
  const [ term, setTerm ] = useState(defaultValues.term);
  const [ leadTime, setLeadTime ] = useState(defaultValues.leadTime);
  const [ productPricingStatus, setProductPricingStatus ] = useState(defaultValues.productPricingStatus);
  const [ designType, setDesignType ] = useState(defaultValues.designType);

  // Using a ref because state values aren't being identified in save design
  const updates = useRef({ ...defaultValues });

  // useState - UI Elements
  const [ modal, setModal ] = useState(null);
  const [ isSaving, setIsSaving ] = useState(false);

  const validator = useDesignValidator();
  const { validate, clearError, validationErrors, setServerErrors } = validator;

  const updateLocationZ = opt => {
    updates.current.locationZ = opt;
    setLocationZ(opt);
  };

  // Design Editing hooks
  const {
    items,
    itemTotals,
    mapServerErrorsToRows,
    hasEdits,
    clearEdits,
    clearRows,
    editLog,
    onChangeRow,
    addRow,
    moveRow,
    deleteRow,
    rowOptions,
  } = useDesignItemsManager({ design, clearError, updateLocationZ, port: props.port, bandwidth: props.bandwidth, term: props.term[0] });

  const { updateTotals, ...pricing } = useDesignPricing({ ...design, quoteOrgId: partnerId || quoteOrgId, quoteOwnerId, partnerType, clearError });

  useEffect(() => {
    updateTotals(itemTotals, editLog);
  }, [ updateTotals, itemTotals, editLog ]);

  // Component method to save design
  const saveDesign = useCallback(async () => {
    const { term, designType, leadTime } = updates.current;

    const { isValid } = validate({ designType, leadTime, term, items, pricing });

    if (!isValid) return;
    
    if (pricing.marginAmt <= 0) {
      return setModal(EDITOR_MODALS.NO_MARGIN);
    }

    await handleSave();
  }, [ validate, items, pricing, handleSave ]);

  const handleSave = useCallback(async () => {
    const isUserUpdateNote = x => (
      x.includes('Manually created/updated by user_id:') ||
      x.includes('Updated by user:')
    );

    const note = (props.design?.note || '')
      .split(',')
      .map(x => !isUserUpdateNote(x) ? x : `Updated by user: ${props.user.id} (${props.user.name})`)
      .join(',');

    setIsSaving(true);

    // Update UI view of checked is solution while save request is being made
    onToggleIsSolution?.(isSolution);

    const data = {
      ...design,
      total: { ...itemTotals, ...pricing },
      ...updates.current,
      items,
      specification,
      status: design.status,
      note,
      currency: quoteCurrency,
    };
    const request = requestMapper.toDesign(data);
    const { error, ...resp } = await upsertDesign(request);

    setIsSaving(false);
    !error && clearEdits();

    return (error)
      ? handleSaveError(error)
      : onSave?.({ designEditorId, design: toDesigns({ results: [resp] })[0] });
  }, [clearEdits, design, designEditorId, handleSaveError, isSolution, itemTotals, items, onSave, onToggleIsSolution, pricing, props.design?.note, props.user.id, props.user.name, quoteCurrency, specification]);

  const cancelEditing = useCallback(() => {
    hasEdits ? setModal(EDITOR_MODALS.CANCEL) : onConfirmModal();
  }, [ hasEdits, onConfirmModal ]);

  const handleSaveError = useCallback(({ design_items, ...unhandledErrors }) => {
    mapServerErrorsToRows(design_items);
    setServerErrors(unhandledErrors);
  }, [ mapServerErrorsToRows, setServerErrors ]);

  const onCloseModal = useCallback(() => {
    setModal(null);
  }, []);

  const onConfirmModal = useCallback(() => {
    clearRows();
    onCancel({ designEditorId });
    onCloseModal();
  }, [ clearRows, onCancel, designEditorId, onCloseModal ]);

  const onConfirmNoMargin = useCallback(() => {
    onCloseModal();
    handleSave();
  }, [ onCloseModal, handleSave ]);

  const toggleIsSolution = () => {
    updates.current.isSolution=!isSolution;
    setIsSolution(!isSolution);
  }

  const onChange = useMemo(() => ({
    term: opt => {
      clearError('term');
      setTerm(opt);
      updates.current.term=opt;
    },
    designType: opt => {
      clearError('designType');
      setDesignType(opt);
      updates.current.designType=opt;
    },
    leadTime: opt => {
      clearError('leadTime');
      setLeadTime(opt);
      updates.current.leadTime=opt;
    },
    productPricingStatus: opt => {
      clearError('productPricingStatus');
      setProductPricingStatus(opt);
      updates.current.productPricingStatus=opt;
    },
  }), [ clearError ]);

  return {
    isSolution,
    isSaving,
    saveDesign,
    cancelEditing,
    onChange,
    onChangeRow,
    validationErrors,
    modal,
    onCloseModal,
    onConfirmModal,
    onConfirmNoMargin,
    options,
    rowOptions,
    defaultValues,
    toggleIsSolution,
    term,
    leadTime,
    designType,
    addRow,
    moveRow,
    deleteRow,
    items,
    pricing,
    locationZ,
    productPricingStatus
  }
};

export default useDesignEditor;


