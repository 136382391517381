import get from 'lodash/get';
import { apiUrlIdStripper } from 'Utils/ccm.js';
import { formatBytes, formatDate, getInternetServiceRequirements } from 'Utils/formatter.js';

import { GET, POST, PATCH, PUT, UPLOAD, DELETE, DOWNLOAD } from '../service.js';

export const getQuoteOptions = (data={}) => {
  const request = requestMapper.getQuoteOptions(data);
  
  return GET('/ccm/api/v3/quote-options/', request)
    .then(responseMapper.quoteOptions);
}

export const getQuoteRequirements = (id) => {
  return GET(`/ccm/api/v3/quotes/${id}/`)
    .then(responseMapper.getQuoteRequirements);
}

export const getDesigns = (request) => {
  return GET(`/ccm/api/v3/rpc/designs/`, request)
    .then(responseMapper.getDesigns);
}

export const getQuoteDesigns = (id, page=1) => {
  return GET('/ccm/api/v3/designs/', { quote_id: id, page, include_design_items: true, include_has_order_draft: true });
}

export const getDesignByReference = (reference) => {
  return GET('/ccm/api/v3/designs/', { reference, include_design_items: false });
}

export const getQuotes = (data) => {
  return GET('/ccm/api/v3/quotes/', data);
}

const createQuote = (request) => {
  return POST('/ccm/api/v3/quotes/', request);
}

export const loginPacketFabric = (request) => {
  return POST('/ccm/redirect/', request, { unauth: true, acceptHeader: 'application/json' });
}

export const getQuoteEvents = (id) => {
  return GET('/ccm/api/v3/quote-events/', { quote_id: id })
    .then(responseMapper.getQuoteEvents);
}

// used for multiple bandwidth selections
export const createQuotes = (data) => {
  const request = requestMapper.upsertQuote(data);

  return POST('/ccm/api/v3/rpc/request-quotes/', request)
    .then(responseMapper.createQuotes);
}

export const updateQuote = (id, request) => {
  return PATCH(`/ccm/api/v3/quotes/${id}/`, request);
}

export const upsertQuote = ({ id, ...data }) => {
  const request = requestMapper.upsertQuote(data);  
  return id ? updateQuote(id, request) : createQuote(request);
}

export const deleteQuote = (id) => {
  return POST(`/ccm/api/v3/quotes/${id}/close/`);
}

export const requestManualPricing = (id) => {
  return POST (`/ccm/api/v3/quotes/${id}/requires-manual-pricing/`);
}

export const noBidPricing  = (id) => {
  return POST (`/ccm/api/v3/quotes/${id}/no-bid/`);
}

export const sendQuoteNotifications = (quoteId) => {
  return POST (`/ccm/api/v3/rpc/send-notifications/`, { quote_id: quoteId });
}

export const copyDesign = (designId, quoteId) => {
  return POST(`/ccm/api/v3/rpc/designs/copy/`, { design_ids: [ designId ], quote_id: quoteId });
}

export const generateTestToken = (data) => {
  const request = requestMapper.generateTestToken(data);
  return POST('/auth-test/generate-test-token/', request);
}

export const generateUgToken = (data) => {
  const request = requestMapper.generateUgToken(data);
  return POST('/accounts/api/v1/token/', request);
}

export const priceQuote = (id) => {
  return POST(`/ccm/api/v3/quotes/${id}/price/`);
}

export const priceFolder = id => {
  return POST(`/ccm/api/v3/folders/${id}/price-quotes/`);
}

export const reviewRequest = (quoteId, reasons, comment) => {
  return POST(`/ccm/api/v3/quotes/${quoteId}/review/request/`, { reasons, comment_message: comment || undefined });
}

export const reviewDismiss = (quoteId, sendEmail=false) => {
  return POST(`/ccm/api/v3/quotes/${quoteId}/review/complete/`, { send_email: sendEmail });
}

export const orderQuote = (id, request) => {
  return POST(`/ccm/api/v3/quotes/${id}/order/`, request);
}

export const manuallyOrderQuote = (id, designId) => {
  return POST(`/ccm/api/v3/quotes/${id}/order/manual/`, { design_id: designId });
}

export const rejectOrder = (id, request) => {
  return POST(`/ccm/api/v3/quotes/${id}/order/reject/`, request);
}

export const cancelOrder = (quoteId, { designId, reason }) => {
  return POST(`/ccm/api/v3/quotes/${quoteId}/order/cancel/`, { design_id: designId, reason });
}

export const createQuoteOrderDraft = (quoteId, request) => {
  return POST(`/ccm/api/v3/quotes/${quoteId}/order-drafts/`, request);
}

export const updateQuoteOrderDraft = (quoteId, draftId, request) => {
  return PUT(`/ccm/api/v3/quotes/${quoteId}/order-drafts/${draftId}/`, request).then(resp => ({ ...resp, id: draftId }));
}

export const acceptOrderReview = (quoteId, request) => {
  return POST(`/ccm/api/v3/quotes/${quoteId}/order/review/`, request);
}

export const validateOrder = (quoteId, designId) => {
  return POST(`/ccm/api/v3/quotes/${quoteId}/order/validate/`, { design_id: designId });
}

export const approveDesign = (id, approvalType) => {
  return POST(`/ccm/api/v3/designs/${id}/approve/`, { approval_type: approvalType });
}

export const rejectDesign = (id, reason, rejectionType) => {
  const request = { reason, send_email: true, rejection_type: rejectionType };
  return POST(`/ccm/api/v3/designs/${id}/reject/`, request);
}

export const resetDesignApprovals = (id, data) => {
  const request = requestMapper.resetDesignApprovals(data);
  return POST(`/ccm/api/v3/designs/${id}/reset-approvals/`, request);
}

export const getOrders = (designId) => {
  return GET('/ccm/api/v3/orders/', { design_id: designId });
}

export const getOrderReviewHtml = (designId) => {
  return GET('/ccm/api/v3/rpc/customer-order-html/', { design_id: designId }, { html: true });
}

export const downloadOrderReviewPdf = (id, fileName) => {
  return DOWNLOAD('/ccm/api/v3/rpc/customer-order-pdf/', fileName, { order_id: id }, 'GET');
}

export const getQuoteOrderDrafts = (quoteId, request) => {
  return GET(`/ccm/api/v3/quotes/${quoteId}/order-drafts/`, request);
}

export const deleteQuoteOrderDraft = (quoteId, draftId) => {
  return DELETE(`/ccm/api/v3/quotes/${quoteId}/order-drafts/${draftId}/`);
}

export const getQuoteError = (id) => {
  return GET(`/ccm/api/v3/quote-errors/${id}/`).then(responseMapper.getQuoteError);
}

export const deleteQuoteError = (id) => {
  return DELETE(`/ccm/api/v3/quote-errors/${id}/`);
}

export const getFolders = (data) => {
  return GET('/ccm/api/v3/folders/', data);
}

export const createFolder = (name) => {
  return POST('/ccm/api/v3/folders/', { name });
}

export const updateFolder = (id, request) => {
  return PATCH(`/ccm/api/v3/folders/${id}/`, request);
}

export const deleteFolder = (id) => {
  return POST(`/ccm/api/v3/folders/${id}/deactivate/`);
}

export const cloneFolder = (data) => {
  const request = requestMapper.cloneFolder(data);
  return POST(`/ccm/api/v3/rpc/bulk-clone-quotes/`, request);
}

export const assignFolder = (folder_id, assignee_email) => {
  return POST(`/ccm/api/v3/rpc/assign-folder/`, { folder_id, assignee_email });
}

export const getOrganizations = (data={}) => {
  const { providerType, ...additionalFilters } = data;
  const request = Object.assign(additionalFilters, requestMapper.getProviders(providerType));

  return GET('/accounts/api/v1/organizations/', request);
}

export const getOrganization = (orgId) => {
  return GET(`/accounts/api/v1/organizations/${orgId}/`);
}

export const getOrganizationNotifications = (orgId, data) => {
    const request = requestMapper.getOrganizationNotifications(data);
    return GET(`/accounts/api/v1/organizations/${orgId}/notifications/`, request)
      .then(responseMapper.getOrganizationNotifications);
}

export const addOrganizationNotifications = (orgId, data) => {
  const request = requestMapper.addOrganizationNotifications(data);
  return POST(`/accounts/api/v1/organizations/${orgId}/notifications/add/`, request);
}

export const removeOrganizationNotifications = (orgId, data) => {
  const request = requestMapper.removeOrganizationNotifications(data);
  return POST(`/accounts/api/v1/organizations/${orgId}/notifications/remove/`, request);
}

export const getColos = (data) => {
  const request = requestMapper.getColos(data);

  return GET('/ccm/api/v3/data-centers/', request)
    .then(responseMapper.getColos);
}

export const getPopLists = (orgId) => {
  return GET('/ccm/api/v3/data-center-lists/', { organization_id: orgId, page_size: 200 })
    .then(responseMapper.getPopLists);
}

export const getColoOptions = () => {
  return GET('/ccm/api/v3/data-center-options/')
}

export const getColoCapabilities = (request) => {
  return GET('/ccm/api/v3/data-centers-capability/', request);
}

export const createCustomer = (name, ownerOrganizationId) => {
  const data = { name, owner_organization_id: ownerOrganizationId };
  return POST('/ccm/api/v3/customers/', data);
}

export const getCustomer = (id) => {
  return GET(`/ccm/api/v3/customers/${id}/`);
}

export const getCustomers = (request) => {
  return GET('/ccm/api/v3/customers/', request);
}

export const getUser = (userId) => {
  return GET(`/accounts/api/v1/users/${userId}/`, { include_permissions: true, include_roles: true });
}

export const getUsers = (data) => {
  return GET('/accounts/api/v1/users/', data);
}

export const getUserMe = () => {
  const request = { include_permissions: true, include_roles: true, expand_role_permissions: true };
  return GET('/accounts/api/v1/users/me/', request).then(responseMapper.getUserMe);
}

export const updateUser = (userId, data) => {
  return PATCH(`/accounts/api/v1/users/${userId}/`, data);
}

export const addUserPermission = (userId, permission) => {
  return POST(`/accounts/api/v1/users/${userId}/permissions/add/`, { permissions: [permission] });
}

export const removeUserPermission = (userId, permission) => {
  return POST(`/accounts/api/v1/users/${userId}/permissions/remove/`, { permissions: [permission] });
}

export const addUserRole = (userId, role) => {
  return POST(`/accounts/api/v1/users/${userId}/roles/add/`, { roles: [role] });
}

export const removeUserRole = (userId, role) => {
  return POST(`/accounts/api/v1/users/${userId}/roles/remove/`, { roles: [role] });
}

export const acceptUserTerms = (userId) => {
  return POST(`/accounts/api/v1/users/${userId}/accept-policies/`);
}

export const updateOrganization = (id, data) => {
  return PATCH(`/accounts/api/v1/organizations/${id}/`, data);
}

export const getQuoteBatches = (request) => {
  const mappedRequest = requestMapper.getQuoteBatches(request);

  return GET('/ccm/api/v3/quote-batches/', mappedRequest)
    .then(responseMapper.getQuoteBatches);
}

export const getQuotesAndFolders = (request, options) => {
  const mappedRequest = requestMapper.getQuotesAndFolders(request);

  return GET('/ccm/api/v3/rpc/folders-and-quotes/', mappedRequest, options)
    .then(response => responseMapper.getQuotesAndFolders(request, response));
}

export const getAllComments = (request) => {
  return GET('/ccm/api/v3/comments/', request);
}

export const getVendorContacts = () => {
  return GET('/ccm/api/v3/rpc/vendor-contact/')
}

export const getQuoteVendors = (specId) => {
  const request = { quote_specification_id: specId, page_size: 500 };

  return GET('/ccm/api/v3/pricing-logs/', request).then(responseMapper.getQuoteVendors);
}

// export const getLocationByTerm = (term) => {
//   return GET(`/c2f/address-autocomplete/${term}/`);
// }

export const getRecentCustomers = () => {
  return GET(`/ccm/api/v3/rpc/recent-customers/`).then(responseMapper.getCustomers);
}

export const getRecentLocations = () => {
  return GET(`/ccm/api/v3/rpc/recent-locations/`).then(responseMapper.getRecentLocations);
}

export const getLocationByTerm = (query) => {
  const request = { query, maxresults: 10 };

  return GET(`/here/address-autocomplete/`, request).then(responseMapper.getLocationByTerm);
}

export const getPopAutocomplete = (query, includeHubs=false) => {
  const request = requestMapper.getPopAutocomplete(query, includeHubs);

  return GET(`/ccm/api/v3/rpc/pop-autocomplete/`, request);
}

export const getPlacesAutocomplete = (query, session_id) => {
  const request = { query, session_id };
  
  return POST(`/ccm/api/v3/rpc/place-autocomplete/`, request).then(responseMapper.getPlacesAutocomplete);
}

export const getAutocomplete = (query, session_id, include_hubs) => {
  const request = { query, session_id, include_hubs };
  
  return POST(`/ccm/api/v3/rpc/autocomplete/`, request)
    .then(resp => responseMapper.getAutocomplete(resp, request));
}

export const getPlaceDetails = (string_address, session_id) => {
  const request = { string_address, session_id };
  
  return POST(`/ccm/api/v3/rpc/validate-address/`, request)
    .then(resp => responseMapper.getPlaceDetails(resp, request));
}

// export const validateAddressHERE = (searchtext) => {
//   const request = { searchtext };
//   const options = { unauth: true };

//   return GET(`/here/validate-address/`, request, options)
//     .then(response => responseMapper.validateAddressHERE(response, request));
// }

export const validateAddress = (addressString, countryCode) => {
  const request = { 
    string_address: addressString,
    country_alpha_3: countryCode
  };

  return POST('/ccm/api/v3/rpc/validate-address/', request);
}

export const importQuotes = (request) => {
  const formBody = requestMapper.importQuotes(request);

  return UPLOAD('/ccm/api/v3/rpc/import-quotes/', formBody);
}

export const getImportTemplate = (fileName) => {
  return DOWNLOAD('/ccm/api/v3/rpc/download-import-template/', fileName);
}

export const exportQuotes = (fileName, data) => {
  const request = requestMapper.exportQuotes(data);
  return DOWNLOAD(`/ccm/api/v3/rpc/export-quotes/`, fileName, request);
}

export const exportFolder = data => {
  const request = requestMapper.exportQuotes(data);
  return POST(`/ccm/api/v3/rpc/export-quotes/`, request);
}

export const getDesign = (id, request={}) => {
  return GET(`/ccm/api/v3/designs/${id}/`, request);
}

export const upsertDesign = (request) => {
  return request.id
    ? updateDesign(request)
    : createDesign(request);
};

export const createDesign = (request) => {
  return POST(`/ccm/api/v3/designs/`, requestMapper.createDesign(request));
}

export const updateDesign = ({ id, ...request }) => {
  return PATCH(`/ccm/api/v3/designs/${id}/`, request);
}

export const getRelatedDesigns = (request={}) => {
  return POST('/ccm/api/v3/rpc/related-quotes/', request);
}

export const getDesignTypes = () => {
  return GET('/ccm/api/v3/design-types/');
}

export const getDesignItemTypes = () => {
  return GET('/ccm/api/v3/design-item-types/');
}

export const getQuoteFilterOptions = (request) => {
  // organization_id, 
  // status__in, 
  // user_id,
  // follow_up_category, 
  // state_name, 
  // country_alpha_3,
  // folder_name,
  // service_type_key,
  return POST('/ccm/api/v3/rpc/quotes/filter-options/', request);
}

export const getDesignFilterOptions = (request) => {
  // organization_id, 
  // approval_needed,
  // state_name,
  // country_alpha_3,
  // design_status,
  // service_type_key,
  return POST('/ccm/api/v3/rpc/designs/filter-options/', request);
}

export const getProviders = (type, data={}) => {
  const request = Object.assign(data, requestMapper.getProviders(type));

  return GET('/ccm/api/v3/providers/', request);
}

export const getProviderProducts = request => {
  return GET(`/ccm/api/v3/rpc/provider-products/`, request)
    .then(responseMapper.getProviderProducts);
}

export const createOrganization = (data) => {
  return POST('/accounts/api/v1/organizations/', data);
}

export const createProvider = (data) => {
  // This rpc will create it if it doesn't exist, otherwise update the existing
  // one with the fields added.
  return POST('/ccm/api/v3/rpc/update-provider/', requestMapper.createProvider(data));
}

export const updateProvider = (id, data) => {
  return PATCH(`/accounts/api/v1/organizations/${id}/`, data);
}

export const getAttachments = (quoteId) => {
  return GET(`/ccm/api/v3/quotes/${quoteId}/attachments/`).then(responseMapper.getAttachments);
}

export const downloadAttachment = (quoteId, attachmentId, fileName) => {
  const url = `/ccm/api/v3/quotes/${quoteId}/attachments/${attachmentId}/download/`;

  return DOWNLOAD(url, fileName, {}, 'GET');
}

export const uploadAttachment = (quoteId, data) => {
  const request = requestMapper.uploadAttachment(data);

  return POST(`/ccm/api/v3/quotes/${quoteId}/attachments/`, request);
}

export const deleteAttachment = (quoteId, attachmentId) => {
  return DELETE(`/ccm/api/v3/quotes/${quoteId}/attachments/${attachmentId}/`);
}

export const updateAttachment = (quoteId, attachmentId, data) => {
  return PATCH(`/ccm/api/v3/quotes/${quoteId}/attachments/${attachmentId}/`, data);
}

export const getComments = (quoteId) => {
  return GET(`/ccm/api/v3/quotes/${quoteId}/comments/`).then(responseMapper.getComments);
}

export const updateComment = (quoteId, id, request) => {
  return PATCH(`/ccm/api/v3/quotes/${quoteId}/comments/${id}/`, request);
}

export const createComment = (quoteId, request) => {
  return POST(`/ccm/api/v3/quotes/${quoteId}/comments/`, request);
}

export const createBulkComment = (data) => {
  const request = requestMapper.createBulkComment(data);
  return POST('/ccm/api/v3/rpc/group-action/', request);
}

export const createUsers = (data) => {
  const request = requestMapper.createUsers(data);
  
  return POST(`/ccm/api/v3/rpc/bulk-add-users/`, request);
}

export const resetPassword = (id) => {
  return POST(`/accounts/api/v1/users/${id}/reset_password/`, { send_email: true })
    .then(responseMapper.resetPassword);
}

export const getQuoteTrace = (quoteId) => {
  return POST(`/ccm/api/v3/rpc/price-tracker/`, { quote_id: quoteId });
}

export const getSystemStatusNotification = () => {
  return GET(`/ccm/api/v3/rpc/system-status/`, null, { ignoreErrors: true }).then(responseMapper.getSystemStatusNotification);
}

export const requestSupport = (request) => {
  return POST(`/ccm/api/v3/rpc/request-info/`, request);
}

export const transferFolder = (folderId, userId, sendEmail) => {
  const request = { folder_id: folderId, transfer_to_user_id: userId, send_email: sendEmail };

  return POST(`/ccm/api/v3/rpc/transfer-quote/`, request)
    .then(responseMapper.transferFolder);
}
 
export const transferQuote = (quoteId, userId, sendEmail) => {
  const request = { quote_id: quoteId, transfer_to_user_id: userId, send_email: sendEmail };

  return POST(`/ccm/api/v3/rpc/transfer-quote/`, request)
}

export const getRateEngineContainer = () => {
  return GET('/ccm/api/v3/rpc/rate-engine-container/');
}

export const getRateEngineQueue = () => {
  return GET('/ccm/api/v3/rpc/rate-engine-queue/');
}

export const getRabbitMqQueue = () => {
  return GET('/ccm/api/v3/rpc/rate-engine-queues/');
}

export const getRecentFolderStats = () => {
  return GET('/ccm/api/v3/rpc/recent-folders-stats/');
}

export const requeueFolderPricingRequests = (folderId) => {
  const request = { folder_id: folderId }; 

  return POST('/ccm/api/v3/rpc/rate-engine-requeue/', request);
}
 
export const getAssignableUsers = () => {
  const request = { permissions__in: "ccm.assigned_to_quote" };
  return GET('/accounts/api/v1/users/', request)
    .then(responseMapper.getAssignableUsers);
}

export const getLatLongLocation = request => {
  // latitude, longitude, radius (km), result_limit
  return POST('ccm/api/v3/rpc/reverse-geocode/', request);
}

export const getAuditLog = request => {
  return GET('ccm/api/v3/audit-logs/', request);
}

const requestMapper = {
  getQuoteOptions: (data) => {
    const {port, bandwidth, term, organizationKey} = data;

    return Object.assign(
      {},
      port ? { port_bandwidth: port.value } : {},
      bandwidth ? { bandwidth: bandwidth.value } : {},
      term ? { term: term.map((t) => t.value).join(',') } : {},
      organizationKey ? { on_behalf_of_organization_key: organizationKey } : {}
    );
  },

  upsertQuote: (data) => {
    const { 
      reference, locationA, locationZ, customerName, bandwidth, port, term, circuitId, folder, 
      QinQ, jumboFraming, crossConnectA, crossConnectZ, name, serviceClass, productType,
      sdWan, serviceRequirements, excludedCarriers, currency, autoName, extendedDemarc,
      kmz, diversity, routeProtection, sendUpdateEmail, unitasIpEndUserRate, ipv4,
      partner, partnerType, csaRep, salesRep, priceAsOrganizationKey, popSelectionOrganizationKey,
      noBid,
    } = data;

    const parseAddressInput = (locationData) => {
      if (!locationData) 
        return null;

      const { icon, label, value, address1, cityState, countryCode, ...location } = locationData;
      return location;
    }

    const advanced = [
      QinQ && 'q-in-q',
      jumboFraming && 'jumbo-framing',
      crossConnectA && 'sourcecrossconnect',
      crossConnectZ && 'destcrossconnect',
      sdWan && 'sd-wan',
      extendedDemarc && 'end-user-extended-demarc',
      kmz && 'kmz-file',
      routeProtection && 'route-protection',
      diversity,
      unitasIpEndUserRate && 'use-unitas-ip-end-user-rate',
    ].filter(Boolean);

    const handling = !!noBid && { requires_manual_pricing: false };

    const request = {
      reference,
      location_a: parseAddressInput(locationA),
      location_z: parseAddressInput(locationZ),
      specification: {
        status: 'pending',
        option_selections: {
          advanced: (advanced.length) ? advanced : undefined,
          bandwidth,
          port_bandwidth: port,
          term,
          class_of_service: serviceClass,
          service_requirements: serviceRequirements,
          product_type: productType,
          ipv4_addresses: ipv4 || undefined,
        },
        provider_connection_vendor_blacklist: excludedCarriers,
        pricing_currency: currency,
        skip_auto_price_email: (sendUpdateEmail === undefined ? undefined : !sendUpdateEmail),
      },
      auto_generate_name: autoName,
      name: autoName ? undefined : name,
      customer_name: customerName,
      folder_url: folder,
      ...(partner && {
        partner_organization_type: partnerType,
        partner_organization_url: partner,
        sales_representative_user_id: salesRep,
        csa_user_id: csaRep
      }),
      price_as_organization_key: priceAsOrganizationKey || null,
      pop_selection_organization_key: popSelectionOrganizationKey || null,
      handling: handling || undefined,
      circuit_id: circuitId,
    };

    return request;
  },

  cloneFolder: data => {
    const { folderId, folder, requirements } = data;
    const { port={}, bandwidth={}, term=[], excludedCarriers, currency={}, locationZ } = requirements;
    const internetTypes = ['internet', 'internet-broadband', 'internet-dedicated-access'];
    //getInternetServiceRequirements
    const locVal = (loc) => {
      if (loc === null || ['leastCost', ...internetTypes].includes(loc.value) || loc.popSelectionOrganizationKey) return null;
      if (loc.url)  return { data_center_url: loc.url };
      if (loc.id)   return { id: loc.id, additional_details: loc.additional_details }
      return loc;
    }

    const toObject = (acc, [ key, val ]) => (acc[key] = val, acc);
    const popSelectionOrganizationKey = (locationZ || {}).popSelectionOrganizationKey;
    const getServiceType = (locZ) => (!popSelectionOrganizationKey && !locZ) 
      ? 'internet' : (locZ && internetTypes.includes(locZ.value)) ? locZ.value : 'ethernet';

    const options = Object.entries({
      bandwidth: bandwidth.value,
      port_bandwidth: port.value,
      term: term.length && term.map(x => x.value),
      service_requirements: (locationZ !== undefined) && getServiceType(locationZ),
    }).filter(x=>!!x[1]);

    const option_selections = options.length && options.reduce(toObject, {})

    const specification = {
      status: 'pending',
      ...(option_selections && { option_selections }),
      provider_connection_vendor_blacklist: excludedCarriers?.map(x => x.value),
      pricing_currency: currency.value,
    };

    return {
      source_folder_id: folderId,
      new_folder_name: folder,
      quote_modifications: {
        ...(locationZ !== undefined && {
          location_z: locVal(locationZ),
        }),
        specification,
        pop_selection_organization_key: popSelectionOrganizationKey 
      }
    }
  },

  createProvider: (data) => {
    const { type, provider, contact, address, phone } = data;
    const connectivityCrossConnect = ['provider-connection', 'cross-connect'];

    return {
      name: provider,
      contact_name: contact,
      address,
      phone,
      provides_connectivity_products: connectivityCrossConnect.includes(type) ? true : undefined,
      provides_cross_connect: connectivityCrossConnect.includes(type) ? true : undefined,
      provides_sdwan: (type === 'sd-wan') ? true : undefined
    }
  },

  getProviders: (type) => {
    let request = { page_size: "-1" };
    switch(type) {
      case 'sd-wan':
        request['provides_sdwan'] = true;
        break;

      case 'network-allocation':
      case 'provider-connection':
      case 'out-of-band':
      case 'sdn':
      case 'router':
      case 'ipv4-addresses':
        request['provides_connectivity_products'] = true;
        break;
        
      case 'cross-connect':
        request['provides_cross_connect'] = true;
        break;
    }
    return request;
  },

  importQuotes:({ folder, customer, file, noBid, ...partnerData }) => {
    const partnerDataMappings = {
      'partner': 'partner_organization_id',
      'partnerType': 'partner_organization_type',
      'csaRep': 'csa_user_id',
      'salesRep': 'sales_representative_user_id',
    }
    const formData = new FormData();
    formData.append('folder_id', folder);
    formData.append('customer_id', customer);
    formData.append('workbook', file, file.name);
    formData.append('requires_manual_pricing', !noBid);
    Object.entries(partnerData).forEach(([key, value]) => {
      if (!!value && key in partnerDataMappings) {
        formData.append(partnerDataMappings[key], value);
      }
    })

    return formData;
  },

  getQuotesAndFolders:({ orgId, ownerId, folderId, folderName, status, region, service, pageSize=50, page=1, ordering='-agg_modified_time,-id' }) => {
    return {
      page, 
      page_size: pageSize,
      organization_id: orgId,
      owner_id: ownerId,
      folder_id: folderId, 
      folder_name: folderName !== '.' ? folderName : undefined,
      status__in: status || undefined,
      state_name: region?.length === 2 ? region : undefined, 
      country_alpha_3: region?.length === 3 ? region : undefined,
      location_type_key: region === 'unvalidated' ? region : undefined,
      service_type_key: service,
      include_inactive: status && status.includes('closed') || undefined,
      ordering
    }
  },

  uploadAttachment: data => {
    const { file, description="Testing...", isInternal } = data;
    const formData = new FormData();

    formData.append('is_internal', isInternal);
    formData.append('description', description);
    formData.append('file', file, file.name);

    return formData;
  },

  createBulkComment: ({ comment, quotes, folderId, status, isInternal=true }) => ({
    group_type: 'quote',
    action: 'comment',
    content: comment,
    is_internal: isInternal,
    ...(quotes && {
      object_ids: quotes
    }),
    ...(folderId && {
      filter_params: {
        folder_id: folderId,
        status__in: status,
      }
    }),
  }),

  createUsers: (data) => {
    const { organizationId, emailCC, users=[] } = data;

    const request = { 
      organization_id: organizationId,
      cc_email: emailCC,
      users: users.map(({ firstName, lastName, email }) => ({ first_name: firstName, last_name: lastName, email }))
    }

    return request;
  },

  getQuoteBatches: ({folderName, requestingUserId, status}) => {
    return {
      folder_name: folderName || undefined,
      requesting_user_id: requestingUserId || undefined,
      status: status || undefined
    };
  },

  generateTestToken: (data) => {
    return {
      token_type: data.tokenType || 'equinix-fabric',
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email
    };
  },

  getColos: (data={}) => ({
    //distinct_address: true,
    on_behalf_of_organization_key: data.organizationKey,
    include_hubs: data.includeHubs
  }),

  createDesign: (data={}) => ({
    ...data,
    creation_source: (process.env.CI_COMMIT_SHA) ? `CCM Web (version ${process.env.CI_COMMIT_SHA})` : 'CCM Web'
  }),

  exportQuotes: (data={}) => ({
    quote_id: data.quoteId || undefined,
    folder_id: data.folderId || undefined,
    export_type: data.exportType,
    filter_for_solutions: data.filterForSolutions
  }),

  resetDesignApprovals: (approvalTypes) => ({
    approval_types: approvalTypes.map(type => (
      type === 'networkEngineer'
        ? 'network_engineering' :
      type === 'pricingDesk'
        ? 'pricing_desk'
        : type
    ))
  }),

  generateUgToken: (data={}) => ({
    user_id: data.userId || undefined
  }),

  getPopAutocomplete: (query, includeHubs=false) => ({
    query,
    include_hubs: includeHubs
  }),

  getOrganizationNotifications: (data={ includeDetails: true }) => ({
    include_details: data.includeDetails
  }),

  addOrganizationNotifications: (data={}) => ({
    all_notification_user_emails: data.allNotificationUserEmails
  }),

  removeOrganizationNotifications: (data={}) => ({
    all_notification_user_emails: data.allNotificationUserEmails
  }),
}

const responseMapper = {
  quoteOptions: ({results=[]}) => {
    const transformOptionType = (optionType) => {
      if (optionType === 'port_bandwidth') {
        return 'port';
      }
      return optionType;
    };

    const transformConstraints = (constraint) => {
      let outputConstraint = {};
      Object.entries(constraint).forEach(([optionType, data]) => {
        const { required, selections } = data;
        
        outputConstraint[transformOptionType(optionType)] = { required, selections: new Set(selections) };
      });
      return outputConstraint;
    };

    const toOption = ({ key, name, numeric_value, option_type, value_type, constraints=[{}] }) => ({ 
      label: name, 
      value: key, 
      sortVal: numeric_value, 
      optionType: transformOptionType(option_type),
      valueType: value_type,
      constraints: transformConstraints(constraints)
    });
    
    return results.map(toOption);
  },

  getQuoteRequirements: (result) => {
    if (result.error) return { error: result.error };

    const spec = (result.specification || {});
    const selections = (spec.option_selections || {});
    const advanced = new Map((selections.advanced || []).map(x => ([x, 1])));
    const assignedName = result.assignee_name;

    const isOrderDeclined = result?.status_details?.order_rejection_data?.[0];
    const statusDetails = isOrderDeclined
      ? 'Order Approval Declined'
      : Object.values(result?.status_details)?.sort().join(', ');

    const mapped = {
      id: result.id,
      reference: result.reference,
      autoName: result.auto_generate_name,
      name: result.name,
      status: result.status,
      statusDetails,
      folderId: result.folder_url && apiUrlIdStripper(result.folder_url),
      folderName: result.folder_name,
      folderUrl: result.folder_url,
      followUpCategory: result.follow_up_category,
      createTime: result.create_time,
      customerId: result.customer_url && apiUrlIdStripper(result.customer_url),
      customerName: result.customer_name,
      customerUrl: result.customer_url,
      locationA: result.location_a,
      locationZ: result.location_z,
      orgUrl: result.organization_url,
      orgId: apiUrlIdStripper(result.organization_url),
      orgName: result.organization_name,
      userName: result.user_name,
      userEmail: result.user_email,
      userUrl: result.user_url,
      popSelectionOrganizationKey: result.pop_selection_organization_key,
      assignedEmail: result.assignee_email,
      assignedName: assignedName,
      hasUnsentNotifications: result.has_unsent_design_change_notifications,

      // partner info
      priceAsOrganizationKey: result.price_as_organization_key,
      partnerId: result.partner_organization_url && apiUrlIdStripper(result.partner_organization_url),
      partnerUrl: result.partner_organization_url,
      partnerName: result.partner_organization_name,
      partnerType: result.partner_organization_type,
      salesRepId: result.sales_representative_user_id,
      salesRepEmail: result.sales_representative_user_email,
      salesRepName: result.sales_representative_user_name,
      csaId: result.csa_user_id,
      csaEmail: result.csa_user_email,
      csaName: result.csa_user_name,
      partnerCommission: result.mrr_partner_commission,

      // specification
      specId: spec.id,
      specStatus: spec.status,
      specReference: spec.reference,
      currency: spec.pricing_currency,
      excludedCarriers: spec.provider_connection_vendor_blacklist || [],
      excludedCarrierNames: spec.provider_connection_vendor_blacklist_details?.map(x => x.name) || [],

      // option selections
      selections,
      bandwidth: selections.bandwidth,
      upload: selections.bandwidth_upload_mbps,
      port: selections.port_bandwidth,
      term: selections.term,
      productType: selections.product_type,
      serviceClass: selections.class_of_service,
      serviceRequirements: selections.service_requirements,
      ipv4: selections.ipv4_addresses,
      crossConnectA: advanced.has('sourcecrossconnect'),
      crossConnectZ: advanced.has('destcrossconnect'),
      extendedDemarc: advanced.has('end-user-extended-demarc'),
      QinQ: advanced.has('q-in-q'),
      jumboFraming: advanced.has('jumbo-framing'),
      sdWan: advanced.has('sd-wan'),
      kmz: advanced.has('kmz-file'),
      carrierDiverse: advanced.has('carrier-diverse'),
      routeDiverse: advanced.has('route-diverse'),
      routeDiverseSameCarrier: advanced.has('route-diverse-same-carrier'),
      routeProtection: advanced.has('route-protection'),
      unitasIpEndUserRate: advanced.has('use-unitas-ip-end-user-rate'),
    }

    return mapped;
  },

  getDesigns: ({ results, count, error }) => {
    const toDesign = design => {
      const { id, quote_id, quote_name, quote_reference, status, status_details, modified_time, folder_name, organization_name, partner_organization_name, user_name, reference, location_a, location_z, approval_details, assignee_email, assignee_name, create_time, follow_up_category } = design;
      const { pricing_desk, network_engineering, csa } = approval_details;
      
      return {
        id,
        name: quote_name,
        createdTime: create_time,
        updatedTime: modified_time,
        organization: partner_organization_name || organization_name, 
        user: user_name,
        folder: folder_name,
        reference,
        quoteId: quote_id,
        quoteRef: quote_reference,
        menuId: 'pricing-desk_menu',
        locationA: location_a,
        locationZ: location_z,
        assigned: assignee_name || '',
        assignedEmail: assignee_email,
        followupCategory: follow_up_category,
        status,
        statusDetails: status_details && (status_details.review_reasons || []).join(', '),
        validation: {
          pricingDesk: pricing_desk, 
          networkEngineer: network_engineering,
          csa
        }
      }
    };

    return { error, count, results: results.map(toDesign) };
  },

  createQuotes: resp => {
    const folderName = resp && resp[0] && resp[0].folder_name;
    const error = !folderName && resp;

    return { folderName, error };
  },

  getColos: ({results=[]}) => {
    const toAddress = x => [
      `${x.street_number || x.building_name} ${x.street}`.trim(),
      x.city, x.state, x.postal_code, x.country_alpha_3
    ].filter(a=>!!a).join(', ');

    return results.map(colo => {
      const { active, state, country_alpha_3, id, name, owner_name, region, connection_point_type, provider_location_name, url } = colo;
      const popName = provider_location_name.toLowerCase().includes(owner_name.toLowerCase())
        ? provider_location_name
        : `${owner_name} ${provider_location_name}`;

      return {
        active,
        id,
        name,
        popName,
        state,
        region,
        addressLabel: toAddress(colo),
        countryCode: country_alpha_3,
        provider: owner_name,
        type: connection_point_type,
        url
      }
    })
  },

  getPopLists: (resp) => {
    const { results=[], error } = resp;
    return { 
      error,
      results: results.map(({ id, name, data_centers }) => 
        ({ id, name, pops: data_centers }))
    };
  },

  getUserMe: (resp) => {
    return {
      ...resp, 
      isEcx: resp.organization_name.toLowerCase() === 'equinix fabric',
      isPF: resp.organization_name.toLowerCase() === 'packetfabric',
      isIB: resp.organization_name.toLowerCase() === 'ice blue internet',
    };
  },

  getRecentLocations: ({ location_a, location_z, error }) => {
    // TODO: return mapped response and 
    return error || ({ locationA: location_a, locationZ: location_z });
  },

  getLocationByTerm: ({suggestions=[]}) => {
    return suggestions.filter(({ address }) => !!(address.street && address.houseNumber))
  },

  getPlacesAutocomplete: (results=[]) => {
    // No results returns a 404 error object so shrug...
    return ((results && results.error) 
      ? []
      : results.map(({ description, full_address, place_id }) => ({ 
          label: description,
          icon: 'location',
          value: place_id, 
          isFullAddress: full_address
        }))
    );
  },

  // todo
  getAutocomplete: (results=[], request) => {
    // No results returns a 404 error object so shrug...
    return results?.error 
      ? [] 
      : results.map(result => ({
        ...result,
        additional_details: {
          autocomplete_input: request.query,
          autocomplete_selection: result.provider_location_name || result.description,
          autocomplete_fallback_used: false,
          // TODO: output place id from /validate-address/ call
          location_identifier: result.identifier,
        }
    }));
  },

  getPlaceDetails: (resp, req) => {
    console.log('place details', resp, req)
    return resp;
  },

  getQuoteBatches: ({results=[]}) => {
    return results.map(({id, status, comment, create_time, modified_time, requesting_user_id, folder_id}) => ({
      id,
      status,
      comment,
      createTime: create_time,
      modifiedTime: modified_time,
      requestingUserId: requesting_user_id,
      folderId: folder_id
    }));
  },

  // DEPRECIATED
  // getUserFoldersAndQuotes: (request={}, response) => {
  //   const { error, results, count, next, previous } = response;
  //   const { sort, sortBy, pageSize=50, page=1 } = request;

  //   const toItem = ({ row_type, id, url, name, status, owner_name, agg_modified_time }) => (
  //     { type: row_type, id, url, name, status, owner: owner_name, lastUpdated: agg_modified_time }
  //   );
  //   const byLastUpdateSort = (a, b) => (a.lastUpdated > b.lastUpdated ? -1 : 1);

  //   return ((!!error) 
  //     ? { error }
  //     : {
  //       page,
  //       pageCount: Math.ceil(count/pageSize),
  //       pageSize,
  //       totalItems: count,
  //       items: results.map(toItem).sort(byLastUpdateSort)
  //     }
  //   );
  // },

  // // DEPRECIATED
  // getUserQuotesFromFolder: (request={}, response) => {
  //   const { error, results, count } = response;
  //   const { sort, sortBy, folderName, pageSize=50, page=1, userId } = request;

  //   const toItem = ({ reference, id, url, name, specification, user_name, create_time }) => (
  //     { type: 'quote', id, reference, url, name, status: specification.status, owner: user_name, lastUpdated: create_time }
  //   );

  //   return getFolders({ name: encodeURIComponent(folderName), owner_id: userId }).then(folderResponse => {
  //     const folderResults = folderResponse.results;
  //     const folder = (folderResults && folderResults.length && folderResults[0]);
  //     const networkError = (error || folderResponse.error || !folder);

  //     return ((!!networkError) 
  //       ? { error: networkError }
  //       : {
  //           folderId: folder.id,
  //           page,
  //           pageCount: Math.ceil(count/pageSize),
  //           pageSize,
  //           totalItems: count,
  //           items: results.map(toItem)
  //         }
  //     );
  //   });
  // },

  getQuotesAndFolders: (request, response) => {
    const { error, results, count } = response;
    const { sort, sortBy, folderName, pageSize=50, page=1, ordering } = request;

    const toItem = ({ active, agg_modified_time, id, name, owner_name, reference, row_type, status, url, bandwidth_name, assignee_name, quote_count, organization_name, organization_key }) => 
      ({ active, type: (row_type === 'quote_error' ? 'import-error' : row_type), id, reference, url, name, status, owner: owner_name, date: agg_modified_time, bandwidth: bandwidth_name, assigned: assignee_name, quoteCount: quote_count, organization: organization_name, organizationKey: organization_key });

    return (error 
      ? { error }
      : {
          page,
          pageCount: Math.ceil(count/pageSize),
          pageSize,
          totalItems: count,
          items: results.map(toItem),
          ordering
        }
    );
  },

  getQuoteError: (resp) => {
    const diversityValues = ['carrier-diverse', 'route-diverse-same-carrier', 'route-diverse'];

    // Coherse value into array if not already
    const toTermOption = (terms=[]) => ([].concat(terms)).map(term => ({ label: term+'', optionType: 'term', sortVal: term, value: term, valueType: 'list', constraints: {} }));
    const toLocationOption = (location) => ({ label: location, value: location, string_address: location });
    const toLeastCostOption = popSelectionOrganizationKey => ({ 
      label: 'Least-cost data center/POP location',
      mode: 'least-cost',
      value: 'leastCost',
      popSelectionOrganizationKey
    });
    const internetType = req => getInternetServiceRequirements(req);
    const toInternetOption = value => ({ value, label: internetType(value) });

    // Flatten data
    const data = {
      name: resp.data.name,
      customerName: resp.data.customer_name,
      folder: resp.data.folder_url,
      locationA: resp.data.location_a.string_address,
      locationZ: get(resp, 'data.location_z.string_address'),
      bandwidth: get(resp, 'data.specification.option_selections.bandwidth'),
      port: get(resp, 'data.specification.option_selections.port_bandwidth'),
      term: get(resp, 'data.specification.option_selections.term'),
      advanced: new Map(data?.specification?.option_selections?.advanced?.map(x => ([x,1]))),
      excludedCarrierKeys: get(resp, 'data.specification.provider_connection_vendor_blacklist') || [],
      serviceRequirements: get(resp, 'data.specification.option_selections.service_requirements'),
      popSelectionOrganizationKey: resp.data.pop_selection_organization_key,
      currency: resp.data.specification.pricing_currency,
      csaRepId: resp.data.csa_user_id,
      salesRepId: resp.data.sales_representative_user_id,
      partnerType: resp.data.partner_organization_type,
      partnerName: resp.data.partner_organization_name,
      partnerUrl: resp.data.partner_organization_url,
    }

    // Flatten errors
    const respErrors = resp.errors || {};
    const errors = {
      // TODO: Verify cudstomer errors
      customer: respErrors.customer_url || respErrors.customer_name,
      folder: respErrors.folder_url,
      locationA: respErrors.location_a,
      locationZ: respErrors.location_z,
      bandwidth: get(respErrors, 'specification.option_selections.bandwidth.0'),
      port: get(respErrors, 'specification.option_selections.port_bandwidth.0'),
      term: get(respErrors, 'specification.option_selections.term.0'),
      popSelectionOrganizationKey: get(respErrors, 'pop_selection_organization_key.0')
    }

    const validationErrors = {
      locationA: !errors.locationA ? undefined : data.locationA 
        ? `Unrecognized address: ${data.locationA}` 
        : 'Please select a "From" location.',
      locationZ: (!errors.locationZ && !errors.popSelectionOrganizationKey) ? undefined : data.locationZ
        ? `Unrecognized address: ${data.locationZ}` 
        : (errors.popSelectionOrganizationKey)
        ? `Unrecognized least cost pop organization: ${data.popSelectionOrganizationKey}`
        : 'Please select a "To" location.',
      bandwidth: !errors.bandwidth ? undefined : data.bandwidth 
        ? `Invalid bandwidth speed: ${data.bandwidth}` 
        : 'Please select a bandwidth speed.',
      port: !errors.port ? undefined : data.port 
        ? `Invalid port speed: ${data.port}` 
        : 'Please select a port speed.',
      term: !errors.term ? undefined : data.term 
        ? `Invalid monthly term: ${data.term}` 
        : 'Please select a monthly term.',
    };

    const defaultValues = {
      name: data.name,
      customer: data.customerName || undefined,
      folder: !data.folder ? undefined : { value: apiUrlIdStripper(data.folder), url: data.folder },
      locationA: validationErrors.locationA ? null : toLocationOption(data.locationA),
      locationZ: (
        (validationErrors.locationZ)
          ? null :
        (validationErrors.popSelectionOrganizationKey 
          || data.locationZ === 'Least Cost'
          || data.popSelectionOrganizationKey
        ) ? toLeastCostOption(data.popSelectionOrganizationKey) :
        (internetType(data.serviceRequirements))
          ? toInternetOption(data.serviceRequirements) :
        (data.locationZ)
          ? toLocationOption(data.locationZ) 
          : toLeastCostOption()
      ),
      bandwidth: validationErrors.bandwidth ? null : { value: data.bandwidth },
      port: validationErrors.port ? null : { value: data.port },
      term: validationErrors.term ? [] : toTermOption(data.term),
      crossConnectA: data.advanced.has('sourcecrossconnect'),
      crossConnectZ: data.advanced.has('destcrossconnect'),
      extendedDemarc: data.advanced.has('end-user-extended-demarc'),
      QinQ: data.advanced.has('q-in-q'),
      jumboFraming: data.advanced.has('jumbo-framing'),
      sdWan: data.advanced.has('sd-wan'),
      kmz: data.advanced.has('kmz-file'),
      diversity: diversityValues.find(x => data.advanced.has(x)),
      routeProtection: data.advanced.has('route-protection'),
      excludedCarriers: data.excludedCarrierKeys.map(value => ({ value })),
      currency: data.pricing,
      sendUpdateEmail: false,
      //skipManualPricing: '',

      pricing: {
        csaRepId: data.csaRepId,
        salesRepId: data.salesRepId,
        partnerType: data.partnerType,
        partner: {
          label: data.partnerName, 
          value: data.partnerUrl, 
          id: apiUrlIdStripper(data.partnerUrl),
        }
      },
    }

    const showPartnerData = data.csaRepId || data.salesRepId || data.partnerKey;

    return { defaultValues, validationErrors, showPartnerData };
  },


  //// TODO: refactoring getQuoteError...
  // getQuoteError: (resp) => {
  //   // Flatten data
  //   const values = responseMapper.getQuoteRequirements(resp.data);
  //   const respErrors = resp.errors || {};
  //   const errors = {
  //     customer: respErrors.customer_url,
  //     folder: respErrors.folder_url,
  //     locationA: respErrors.location_a,
  //     locationZ: respErrors.location_z,
  //     bandwidth: get(respErrors, 'specification.option_selections.bandwidth.0'),
  //     port: get(respErrors, 'specification.option_selections.port_bandwidth.0'),
  //     term: get(respErrors, 'specification.option_selections.term.0'),
  //     popSelectionOrganizationKey: get(respErrors, 'pop_selection_organization_key.0')
  //   }
    
  //   return { values, errors };
  // },

  getAttachments: resp => {
    const { results=[] } = resp;
    const toAttachmentInfo = ({ create_user_name, create_user_id, file_name, file_size, id, is_internal, modified_time, description }) => ({
      id,
      name: file_name,
      date: formatDate(modified_time),
      author: create_user_name,
      authorId: create_user_id,
      size: formatBytes(file_size),
      isInternal: is_internal,
      description
    });

    return results.map(toAttachmentInfo);
  },

  getComments: ({ results=[] }) => {
    return results.map(({ id, create_user_name, create_time, is_internal, content }) => ({
      id, name: create_user_name, date: formatDate(create_time), isInternal: is_internal, content
    }));
  },

  getQuoteEvents: ({ results=[] }) => {
    const iconLookup = {
      'quote_assignee_changed': 'user',
      'quote_failed_to_price': 'exclamation-point-circle-outline',
      'quote_priced': 'checkmark-circle-outline',
      'quote_repriced': 'checkmark-circle-outline',
      'quote_repricing_requested': 'refresh-price',
      'design_edited': 'pencil',
      'quote_transferred': 'key',
      'quote_order_submitted_for_review': 'request-review',
      'quote_order_review_cancelled': 'not-allowed',
    };

    const msgLookup = {
      'quote_repriced': 'Quote Repriced',
      'quote_repricing_requested': 'Repricing Requested',
      'quote_edited': 'Quote Request Edited',
    };

    return results.map(({ id, details, event_time, event_type, message, user_name }, i) => {
      const type = (i > 1 && event_type === 'quote_pricing_requested') 
        ? 'quote_repricing_requested'
        : event_type;

      return {
        id,
        details,
        eventTime: event_time,
        type,
        icon: iconLookup[type] || 'quote', 
        message: msgLookup[type] || message,
        userName: user_name,
      }
    })
  },

  getProviderProducts: (resp) => {
    return resp.error || Object.fromEntries(
      Object.entries(resp).map(([key, products]) => ([
        key, 
        products.filter(x => x.designItemType === 'provider-connection').map(x => x.name)
      ])
    ))
  },

  getQuoteVendors: ({ results=[], error }) => {
    const toRateIcon = x => x.rate_type === 'API' ? 'vendor-api' : 'vendor-rate-card';

    const toResult = ({ pricing_result='' }) => pricing_result.indexOf('Not Available') > -1
      ? 'Not Available'
      : pricing_result;

    const toResultIcon = ({ pricing_result }) => pricing_result === 'Quoted' ? 'quoted' : !pricing_result ? 'draft' : 'error';
    
    const toRows = x => ({
      id: x.id,
      rateType: x.rate_type,
      rateIcon: toRateIcon(x),
      locSource: +(!x.is_location_a), 
      rateEngineId: x.rateengine_rate_identifier,
      proximityA: x.proximity_a,
      proximityASrc: x.proximity_a_source,
      proximityAVal: x.proximity_a_value,
      proximityZ: x.proximity_z,
      proximityZSrc: x.proximity_z_source,
      proximityZVal: x.proximity_z_value,
      proximitySrc: x.proximity_source,
      result: toResult(x),
      criterion: x.criterion,
      resultIcon: toResultIcon(x),
      progress: x.pricing_progress,
      description: x.description,
      product: x.product,
      productType: x.product_type,
      vendor: x.vendor_name,
      designs: x.design_references.join(', '),
    });

    return { error, results: results.map(toRows) };
  },

  // eslint-disable-next-line no-unused-vars
  resetPassword: ({ email_attempted, email_successfully_sent, status, temp_password }) => {
    return {
      emailSent: email_successfully_sent,
      tempPassword: temp_password
    }
  },

  getSystemStatusNotification: ({ results = {} }) => {
    const statuses = Object.values(results);
    const msg = "Quote requests are taking longer than usual to price due to third-party systems we are dependent on. We apologize for the inconvenience.";

    return statuses.length && statuses.every(({ status }) => status !== 'DOWN') ? null : msg;
  },

  transferFolder: resp => {
    const { failed_quote_ids, new_folder_name } = resp;
    return { 
      failedQuotes: failed_quote_ids.length,
      newFolderName: new_folder_name,
    }
  },

  getOrganizationNotifications: resp => {
    const { all_notification_user_emails, all_notification_user_details } = resp;
      return {
        allNotificationUserEmails: all_notification_user_emails,
        allNotificationUserDetails: all_notification_user_details,
      }
  },

  getAssignableUsers: ({ results=[] }) => {
    return results.reduce((obj, x) => Object.assign(obj, { [x.email]: x.name }), {});
  },

  // validateAddressHERE: ({ Response={}, error }, { searchtext }) => {
  //   const filteredResult = (get(Response, 'View.0.Result') || []).filter(loc => loc.MatchLevel === 'houseNumber');
  //   const address = filteredResult.length && filteredResult[0].Location.Address;

  //   if (error || !address) {
  //     return { error: error || `No results found for: ${searchtext}`}
  //   }

  //   const { Label, Country, State, City, Street, HouseNumber, PostalCode } = address;
  //   const addressText = [`${HouseNumber} ${Street}`, City, State].filter(a => !!a).join(', ');

  //   // TODO: Strip line2 data for floor or unit
  //   return {
  //     label: addressText,
  //     countryCode: Country,
  //     address: addressText
  //   }
  // },

  getLatLongLocation: ({ results }) => {
    const toLatLongLocation = result => {
      //const { id, identifier, id_kind, kind, formatted_address, street_number, street, city, state, postal_code, latitude, longitude, extra, building_name, floor, suite, distance } = result;
      return result;
    }

    return { results: results?.map(toLatLongLocation) };
  },
}
