import React from 'react';
import { useUserContext } from 'Context/UserContext.js';
import { Redirect } from 'react-router-dom';

import { Page } from 'Atoms';
import { RateEngineContainer, RateEngineQueue, RecentFolderStats, RabbitMqQueue } from 'Widgets';
import { PERMISSIONS } from 'Utils/const.js';

import './DiagnosticsPage.scss';

const DiagnosticsPage = () => {
  const user = useUserContext();

  return !user.hasPermission(PERMISSIONS.VIEW_SYSTEM_DIAGNOSTICS)
    ? <Redirect to={'/'} /> 
    : (
      <Page className="diagnostics-page patterned" header="System Diagnostics">
        <div className="widget">
          <h2>Rate Engine Container</h2>
          <div className="widget-wrapper">
            <div className="widget-scroller">
              <RateEngineContainer />
            </div>
          </div>
        </div>

        <div className="widget">
          <h2>Rate Engine Queue</h2>
          <div className="widget-wrapper">
            <div className="widget-scroller">
              <RateEngineQueue />
            </div>
          </div>
        </div>

        <div className="widget">
          <h2>RabbitMq Active Queues</h2>
          <div className="widget-wrapper">
            <div className="widget-scroller">
              <RabbitMqQueue />
            </div>
          </div>
        </div>
  
        <div className="widget">
          <h2>Recent Folder Stats</h2>
          <div className="widget-wrapper">
            <div className="widget-scroller">
              <RecentFolderStats />
            </div>
          </div>
        </div>
      </Page>
    )
}

export default DiagnosticsPage;