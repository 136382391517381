import React, { Component, createRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import CN from 'classnames';
import { stringifyUrl } from 'query-string';
import { withUserContext } from 'Context/UserContext.js';
import { withRouter, Link } from 'react-router-dom';
import { Breadcrumbs, Button, Checkbox, ContextMenu, ContextMenuButton, EmailLink, Icon, LoadingIcon, LoadingOverlay, Notification, StatusIcon, Tabs, TabPane, TabBadge } from 'Atoms';
import { CurrencyLookup, ModalManager, MODALS } from 'Molecules';

import { getQuotes, getQuoteRequirements, getOrganization, exportQuotes, priceQuote, updateQuote, noBidPricing, getSystemStatusNotification, reviewDismiss, getAssignableUsers } from 'Actions/ccm/api.js';
import { getQuoteStatus, mailTo } from 'Utils/formatter.js';
import { capitalize, slugify } from 'Utils/strings.js';
import { DefFaviconName, PricingFavicon, QuotedFavicon, ManualFavicon, COMPANY_SHORT_NAME } from 'Utils/branding.js';
import { apiUrlIdStripper, getReferenceType } from 'Utils/ccm.js';
import { PERMISSIONS, SUPPORT_EMAILS } from 'Utils/const.js';

import { responseMapper, toFormattedRequirements } from 'Pages/CCM/QuoteView/QuoteViewerMapper.js';

import QuoteRequirements from './QuoteRequirements.jsx';
import WhatsNext from './WhatsNext.jsx';
import { AttachmentsTab, CommentsTab, DesignsTab, HistoryTab, RelatedTab, TraceTab, VendorsTab } from './Tabs';

import './QuoteViewer.scss';
import 'Sass/animate.scss';

const MANUAL_STATUSES = ['manual', 'manual-pricing-needed', 'error', 'error-pricing'];
const LOCKED_STATUSES = ['closed', 'installed', 'ordering-pending-approval', 'ordered', 'pricing', 'provisioning', 'no-bid'];
const REVIEWABLE_STATUSES = [ 'quoted', 'edited', 'manually-quoted' ];
const TAB_KEYS = ['designs', 'comments', 'attachments', 'trace', 'related'];

const getInitialState = (quoteRef) => {
  const { hash, origin, pathname } = window.location;
  const tab = hash && hash.substr(1);
  let activeKey = 'designsTab';

  (tab && TAB_KEYS.includes(tab)) 
    ? activeKey = `${tab}Tab`
    : window.history.replaceState({}, '', `${origin}${pathname}`);

  return {
    isPricing: null,
    quoteRef,
    id: null,
    specId: null,
    status: '',
    statusDetails: null,
    requirements: {},
    attachments: 0,
    comments: 0,
    pollingDuration: 0,
    outageNotification: null,
    showingError: false,
    loadingMessage: null,
    notificationMessage: null,
    ownerOrgId: null,
    ownerId: null,
    activeKey,
    ownerOrg: null,
    focusedDesignId: null,
    assignableUsers: {},
  }
};

const FOLLOWUP_CATEGORIES = ['Pending sales', 'Waiting on customer', 'Waiting on vendor'];

class QuoteViewer extends Component {
  constructor(props) {
    super(props);
    const quoteRef = props.match.params.quoteRef;

    this.state = getInitialState(quoteRef);

    this.designPoller = this.designPoller.bind(this);
    this.getQuoteData = this.getQuoteData.bind(this);
    this.focusDesign = this.focusDesign.bind(this);
    this.userHasQuotePermission = this.userHasQuotePermission.bind(this);
    this.getContextMenuItems = this.getContextMenuItems.bind(this);
    this.onClickExport = this.onClickExport.bind(this);
    this.onDeleteQuote = this.onDeleteQuote.bind(this);
    this.handleToNoBid = this.handleToNoBid.bind(this);
    //this.onRenameQuote = this.onRenameQuote.bind(this);
    this.onRepriceQuote = this.onRepriceQuote.bind(this);
    this.onTransferQuote = this.onTransferQuote.bind(this);
    this.setStatus = this.setStatus.bind(this);
    this.setCount = this.setCount.bind(this);

    this.modalRef = createRef(null);
  }
  
  static getDerivedStateFromProps(props, state) {
    if (props.match.params.quoteRef !== state.quoteRef) {
      return getInitialState(props.match.params.quoteRef);
    }
    
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.quoteRef !== prevState.quoteRef) {
      this.getQuoteData();
    }
  }

  setStatus(status, callback) {
    this.setState({ status }, callback);
  }

  setCount(stateKey) {
    return (value) => {
      this.setState({ [stateKey]: value });
    }
  }

  getQuoteData() {
    return getQuotes({ reference: this.state.quoteRef, include_closed: this.props.user.is_internal })
      .then(resp => {
        const mapped = responseMapper.getQuotes(resp);
        const { 
          error, id, name, status, statusDetails, isPricing, specId, 
          folderName, folderUrl, userName, userEmail, 
          ownerOrgId, ownerId, ownerOrgName, partnerOrgName
        } = mapped;

        if (error) {
          return this.setState({ showingError: true });
        }

        this.setState({ id, name, status, statusDetails, isPricing, specId, folderName, folderUrl, userName, userEmail, ownerOrgName, ownerOrgId, ownerId });
        this.designPoller(id);
        if (isPricing) {
          getSystemStatusNotification().then(outageNotification => this.setState({ outageNotification }));
        }
        if (this.props.user.is_internal && !partnerOrgName) {
          getOrganization(ownerOrgId).then(resp => this.setState({ quotingOrganization: resp }));
        }

        return mapped;
      });
  }

  focusDesign(designId) {
    this.setState({ activeKey: 'designsTab', focusedDesignId: designId });
  }

  handleToNoBid() { 
    noBidPricing(this.state.id).then(() => window.location.reload());
  }

  userHasQuotePermission(permission) {
    // TODO: Add some sort of permitted actions api
    const { user } = this.props;
    const {id, ownerOrgId, ownerId} = this.state;
    const perms = [
      permission,
      `${permission}:quote=${id}`,
      `${permission}:organization=${ownerOrgId}`,
      `${permission}:user=${ownerId}`,
      // Role permissions are expanded to user permissions by the "expand_role_permissions=true" parameter
    ];
    return user && user.permissions && user.permissions.some((p) => perms.includes(p));
  }

  getContextMenuItems() {
    const { modalRef, onDeleteQuote, onRenameQuote, onRepriceQuote, onClickExport, onTransferQuote } = this;
    const { name, quoteRef, requirements, status, assignableUsers } = this.state;
    const { user } = this.props;
    const { reference, id, ...clonedRequirements } = requirements;

    const updateRequirements = updates => {
      const requirements = { ...this.state.requirements, ...updates };
      this.setState({ requirements });
    };

    const setLoadingMessage = loadingMessage => this.setState({ loadingMessage });

    const openModal = (modal, props) => () => modalRef.current && modalRef.current.open(modal, props);
    const onAssignUser = (email, name) => () => {
      const assignedEmail = requirements.assignedEmail !== email ? email : null;
      setLoadingMessage('Updating assigned user...');
      updateQuote(id, { assignee_email: assignedEmail }).then(({ error }) => {
        if (error) return alert(`Unable to assign quote to user.`);

        updateRequirements({ assignedEmail: assignedEmail, assignedName: assignedEmail ? name : undefined });
        setLoadingMessage(null);
      })
    };

    const onAssignFollowup = (followUpCategory) => () => {
      const category = requirements.followUpCategory !== followUpCategory ? followUpCategory : null;
      setLoadingMessage('Updating followup category...');
      updateQuote(id, { follow_up_category: category }).then(({ error }) => {
        if (error) return alert(`Unable to update followup category`);

        updateRequirements({ followUpCategory: category });
        setLoadingMessage(null);
      })
    };

    const deleteMessage = 'Deleting this quote can not be undone, and you will be redirected back to the Quote Manager.';
    const hidden = {
      assign: !user.is_pricing_desk,
      clone: user.isEcx || user.isPF,
      edit: !user.is_pricing_desk,
      exportSubmenu: !user.is_internal ,
      noBid: !user.is_pricing_desk || !MANUAL_STATUSES.includes(status),
      reprice: !user.hasPermission(PERMISSIONS.REPRICE_QUOTE),
      review: !user.is_pricing_desk && (!REVIEWABLE_STATUSES.includes(status) || !this.userHasQuotePermission('ccm.request_quote_review')),
      transfer: !user.is_internal && !user.is_org_manager,
      icbEmail: !user.is_pricing_desk || ![...MANUAL_STATUSES, 'review', 'request-review'].includes(status),
    };

    const disabled = {
      export: !this.state.id,
    };

    const isSelected = {
      assign: email => email && email === requirements.assignedEmail,
      followup: cat => cat && cat === requirements.followUpCategory,
    };

    const assigneeItems = Object.entries(assignableUsers).map(([email, name]) => ({
      label: name, isSelected: isSelected.assign(email), onClick: onAssignUser(email, name),
    }));
    const followupItems = FOLLOWUP_CATEGORIES.map(label => ({
      label, isSelected: isSelected.followup(label), onClick: onAssignFollowup(label),
    }));

    const statusPermitsAction = (status, action) => {
      if (['edit', 'request-review', 'reprice', 'transfer'].includes(action)) {
        return !LOCKED_STATUSES.includes(status);
      }
      if (['assign', 'edit-followup'].includes(action)) {
        if (['pricing', 'ordered'].includes(status)) {
          // These otherwise locked statuses still allow these actions
          return true;
        }
        return !LOCKED_STATUSES.includes(status);
      }
      return true;
    };

    const contextMenuItems = [
      //{ label: 'Rename', icon: 'pencil', onClick: openModal(MODALS.RENAME, { type: 'quote', onConfirm: onRenameQuote }) },
      { label: 'Clone Quote', icon: 'copy', onClick: openModal(MODALS.CREATE, { requirements: clonedRequirements }), hidden: hidden.clone },
      { label: 'Edit & Requote', icon: 'pencil', onClick: openModal(MODALS.CREATE, { requirements: { ...requirements, sendUpdateEmail: false }, allowUpdateEmail: true }), hidden: hidden.edit, disabled: !statusPermitsAction(status, 'edit') },
      { label: 'Request Review', 
        icon: 'request-review',
        onClick: openModal(MODALS.REQUEST_REVIEW, { quoteId: this.state.id, onConfirm: () => window.location.reload() }), 
        hidden: hidden.review,
        disabled: !statusPermitsAction(status, 'request-review'),
      },
      {
        icon: 'envelope',
        label: 'Vendor ICB',
        onClick: openModal(MODALS.CONTACT_VENDOR, { selected: [ this.state.requirements ] }),
        hidden: hidden.icbEmail,
      },
      { label: 'Reprice', icon: 'refresh-price', onClick: onRepriceQuote, hidden: hidden.reprice, disabled: !statusPermitsAction(status, 'reprice') },
      { label: 'Transfer', icon: 'key', onClick: openModal(MODALS.TRANSFER_QUOTE, {
          onConfirm: onTransferQuote,
          organization: requirements.partnerId || requirements.orgId,
          ownerId: +apiUrlIdStripper(requirements.userUrl || ''), 
          id: this.state.id, 
          reference: quoteRef,
        }),
        disabled: !statusPermitsAction(status, 'transfer'),
        hidden: hidden.transfer,
      },
      { submenu: "Assign", selectable: true, icon: 'user', hidden: hidden.assign, disabled: !statusPermitsAction(status, 'assign'), items: assigneeItems },
      { submenu: "Followup", selectable: true, icon: 'calendar', hidden: hidden.assign, disabled: !statusPermitsAction(status, 'edit-followup'), items: followupItems },
      (hidden.exportSubmenu
      ? { label: 'Export Quote', icon: 'export', onClick: onClickExport(), disabled: disabled.export }
      : { submenu: 'Export Quote',
          icon: 'export',
          disabled: disabled.export, 
          items: [
            { label: 'Internal view', onClick: onClickExport('internal', true) },
            { label: 'Internal view showing all designs', onClick: onClickExport('internal', false) },
            { label: 'External view', onClick: onClickExport('external', false) },
        ]}
      ),
      { label: 'Set as No Bid', icon: 'add-circle-outline', onClick: this.handleToNoBid, hidden: hidden.noBid },
      { label: 'Delete', icon: 'trash', onClick: openModal(MODALS.DELETE, { id, name, type: 'quote', onConfirm: onDeleteQuote, message: deleteMessage }) },
    ];

    return contextMenuItems;
  }

  designPoller(id, polled=0) {
    const POLLING_INTERVAL = 5;
    const quoteRef = this.state.quoteRef;
    const quoteId = id || (getReferenceType(quoteRef) === 'Q' && quoteRef.split('Q')[1]);
    
    getQuoteRequirements(quoteId)
      .then(requirements => {
        const pollingDuration = polled * POLLING_INTERVAL;
        const status = requirements.status;
        const isPricing = ['draft', 'pricing', 'pending'].includes(status);

        // If the quote id changed, this could still be in a timeout with the old id
        if (this.state.id !== quoteId) {
          return;
        }
        this.setState({ status, requirements, isPricing, pollingDuration });

        if (isPricing) {
          setTimeout(() => { this.designPoller(id, polled + 1) }, POLLING_INTERVAL * 1000)
        }
      });
  }

  onClickExport(exportType, filterForSolutions) {
    const { id, name } = this.state;
    return () => {
      if (id) {
        const data = {
          quoteId: id,
          exportType: exportType,
          filterForSolutions: filterForSolutions
        }
        exportQuotes(slugify(name) + '.xlsx', data);
      }
    }
  }

  onDeleteQuote({ name }) {
    const { user, history } = this.props;
    const { userUrl, folderName } = (this.state.requirements || {})
    // const notificationMessage = (<span><b>{name}</b> has been deleted</span>);
    // this.setState({ notificationMessage })

    const folderPath = folderName !== '.' ? `${encodeURIComponent(folderName)}/` : '';
    const url = `/quote-manager/${folderPath}`;
    const query = { u: (user.url !== userUrl) ? apiUrlIdStripper(userUrl) : null };

    const redirect = stringifyUrl({ url, query }, { skipNull: true });

    history.push(redirect);
  }

  onRenameQuote({ name, prevName }) {
    const notificationMessage = (<span><b>{prevName}</b> renamed to <b>{name}</b></span>);
    this.setState({ notificationMessage });
  }

  onRepriceQuote() {
    return priceQuote(this.state.id).then(({ error }) => {
      (error) ? this.setState({ error }) : window.location.reload();
    });
  }

  onTransferQuote() {
    this.setState({ loadingMessage: ' '});
    this.getQuoteData().then(({ userName }) => {
      const notificationMessage = (<span>Ownership of quote <b>{this.state.quoteRef}</b> has been transferred successfully to <b>{userName}</b></span>);
      this.setState({ notificationMessage, loadingMessage: null });
    });
  }

  componentDidMount() {
    const { quoteRef='' } = this.state;

    getAssignableUsers().then((assignableUsers) => this.setState({assignableUsers}));

    if (quoteRef.startsWith('Q')) {
      getQuoteRequirements(quoteRef.substr(1)).then(({ error, reference }) => {
        error ? this.setState({ showingError: true }) : this.props.history.replace(`/quote/${reference}/`);
      })
    } else {
      this.getQuoteData();
    }
  }

  componentWillUnmount() {
    this.designPoller = () => {};
  }

  componentDidCatch(error) {
    console.log(error)
    debugger;
  }

  render() {
    const { focusDesign, setStatus, setCount, getContextMenuItems, getQuoteData } = this;
    const { user, location, history } = this.props;
    const { id, quoteRef, isPricing, name, specId, status, requirements={}, quotingOrganization, attachments, comments, pollingDuration, outageNotification, showingError, loadingMessage, notificationMessage, activeKey, folderName, folderUrl, focusedDesignId } = this.state;
    const formatted = toFormattedRequirements(requirements, quotingOrganization);
    const isManual = MANUAL_STATUSES.includes(status);
    const crumbs = [
      { text: 'Home', link: '/' },
      { text: `Quote Reference: ${quoteRef}` }
    ];

    const quoteStatus = status && getQuoteStatus(status);
    const favIcon = (
      (!quoteStatus) ? null : // use default .ico in root
      (isPricing) ? PricingFavicon :
      (isManual) ? ManualFavicon : QuotedFavicon
    );

    const showVendorTab = !!user.is_pricing_desk;
    const showHistoryTab = !!user.is_internal;
    const showRelatedTab = user?.permissions?.some((p) => p === PERMISSIONS.VIEW_RELATED_TAB) ?? false;
    const showTraceTab = !!user.is_developer || ((!!user.is_admin || !!user.is_pricing_desk) && (location.search.includes('trace')));
    const quoteDesignProps = { status, id, isPricing, setStatus, quoteRef, requirements, pollingDuration, focusedDesignId };

    const classNameContent = CN('quote-viewer__content animated', showingError ? 'zoomOut' : 'fadeIn');
    const toQuoteManager = () => history.push('/quote-manager/');
    const contactSupport = () => {
      let to = SUPPORT_EMAILS.UNITAS_GLOBAL;
      if (user.isEcx) to = SUPPORT_EMAILS.EQUINIX_FABRIC;
      else if (user.isPF) to = SUPPORT_EMAILS.PACKETFABRIC;
      window.location = mailTo({ to, subject: `Support request for accessing quote ${quoteRef}` });
    };
    const onDismiss = () => this.setState({ notificationMessage: null });

    const supportClick = () => {
      const onConfirm = () => this.setState({ notificationMessage: `Your information request has been sent! A ${COMPANY_SHORT_NAME} representative will be in touch with you shortly.` });
      this.modalRef.current.open(MODALS.SUPPORT, { quoteId: id, onConfirm })
    }

    const canEditCurrency = user.is_pricing_desk && !LOCKED_STATUSES.includes(status);

    const onChangeCurrency = ({ value }) => {
      if (value !== formatted.currency) {
        const onConfirm = () => {
          this.setState({ loadingMessage: `Updating currency to ${value}...` });

          updateQuote(this.state.id, {'specification': {'pricing_currency': value }}).then(({ error }) => {
            (error) ? this.setState({ error, loadingMessage: null }) : window.location.reload();
          });
        }

        this.modalRef.current && this.modalRef.current.open(MODALS.CONFIRMATION, {
          label: "Update Quote Currency",
          children: <>Are you sure you want to update the currency of this quote and all of its existing designs from <b>{formatted.currency}</b> to <b>{value}</b>?</>,
          onConfirm
        });
      }
    };

    const contextMenuProps = { 
      id: 'quote-viewer-menu-id', 
      items: getContextMenuItems(), 
      className: 'quote-viewer__context-menu' 
    };

    const handleTabChange = activeKey => {
      this.setState({ activeKey });
      const { origin, pathname } = window.location;

      window.history.replaceState({}, '', `${origin}${pathname}#${activeKey.replace('Tab', '')}`);
    }

    return (
      <div className="quote-viewer">
        { !!quoteStatus && !!favIcon && (
          <Helmet>
            <title>{`(${quoteStatus.text}) ${name}`}</title>
            <link rel={DefFaviconName} type="image/png" href={favIcon} />
          </Helmet>
        )}

        {!!notificationMessage && (
          <Notification.Success duration={7500} onDismiss={onDismiss} children={notificationMessage} />
        )}
        
        <Breadcrumbs crumbs={crumbs} />
        
        {!!loadingMessage && <LoadingOverlay className="quote-viewer__loading-overlay" children={loadingMessage} />}

        { !showingError && (
          <div className={classNameContent}>
            <div className="quote-viewer__header">
              <div className="quote-viewer__status">
                {!!status && <StatusIcon status={status} />}
                {!!formatted.statusDetails && !MANUAL_STATUSES.includes(status) && ` (${formatted.statusDetails})` }
              </div>

              {!!formatted.currency && (
                <div className="quote-viewer__currency">
                  <span className="quote-viewer__currency-label">Currency:</span>
                  <CurrencyLookup className="lookup--inline" onChange={onChangeCurrency} value={formatted.currencyOption} isDisabled={!canEditCurrency} />
                </div>
              )}
            </div>
            
            <div className="quote-requirements">
              <div className="quote-viewer__info">
              <div><b>Quote Reference: { quoteRef }</b></div>
                <div>{ name }</div>
                { !!folderName && (
                  <div className="quote-viewer__folder-description">
                    <b>↳</b>
                    <Link to={folderUrl}>
                      <Icon name="folder" />{folderName}
                    </Link>
                  </div>
                )}
              </div>
              <div className="quote-viewer__actions">
                <ContextMenuButton menuId={contextMenuProps.id} />
              </div>
            </div>

            <QuoteRequirements requirements={formatted} />
            <br />

            {!!isPricing && !!outageNotification && (
              <Notification.Warning children={outageNotification} />
            )}

            {status === 'review' && (
              <ReviewStatusNotification requirements={requirements} onDismiss={getQuoteData} canCompleteReview={this.userHasQuotePermission('ccm.complete_quote_review')} />
            )}

            <Tabs onChange={handleTabChange} activeKey={activeKey}>
              <TabPane tab="Designs" key="designsTab" forceRender>
                <DesignsTab {...quoteDesignProps} />
              </TabPane>

              <TabPane tab={<TabBadge value={comments} children="Comments" />} key="commentsTab" forceRender>
                { !!id && <CommentsTab id={id} status={status} setCount={ setCount('comments') } /> }
              </TabPane>

              <TabPane tab={<TabBadge value={attachments} children="Attachments" />} key="attachmentsTab" forceRender>
                { !!id && <AttachmentsTab id={id} status={status} setCount={ setCount('attachments') } /> }
              </TabPane>

              {!!showHistoryTab && (
                <TabPane tab="History" key="historyTab">
                  { !!id && <HistoryTab id={id} /> }
                </TabPane>
              )}

              {!!showVendorTab && (
                <TabPane tab="Vendors" key="vendorsTab">
                  { !!id && <VendorsTab id={id} specId={specId} /> }
                </TabPane>
              )}

              {!!showTraceTab && (
                <TabPane tab="Trace" key="traceTab">
                  { !!id && <TraceTab id={id} /> }
                </TabPane>
              )}

              {showRelatedTab && (
                <TabPane tab="Related" key="relatedTab">
                  { !!id && <RelatedTab id={id} serviceType={requirements.serviceRequirements} focusDesign={focusDesign} /> }
                </TabPane>
              )}
            </Tabs>

            <WhatsNext supportClick={supportClick} />
          </div>
        )}

        {!!showingError && (
          <div className="quote-viewer__error animated fadeIn">
            <Icon name="quote-error" />
            <div className="quote-error__icon-caption">{this.state.quoteRef}</div>
            <p className="quote-viewer__error-headline">Unable to retrieve quote information</p>
            <p>
              Either this is an invalid reference number or you are not authorized to view this quote.
            </p>
            <p>
              Please double check that the number is typed correctly and try again.
              If you have tried again and the quote can’t be found,
              please try the options below.
            </p>
            <div className="quote-viewer__error-actions">
              <Button type="tile" onClick={toQuoteManager} children="View Quote Manager" />
              <Button type="tile" onClick={contactSupport} children="Contact Support" />
            </div>
          </div>
        )}
  
        <ContextMenu {...contextMenuProps} />
        <ModalManager ref={this.modalRef} />
      </div>
    )
  }
}

const ReviewStatusNotification = ({ requirements, onDismiss, canCompleteReview }) => {
  const [ sendEmail, setSendEmail ] = useState(false);
  const [ isDismissing, setIsDismissing ] = useState(false);

  const onChange = e => setSendEmail(e.target.checked);
  const onClick = async () => {
    setIsDismissing(true);
    const resp = await reviewDismiss(requirements.id, sendEmail);

    !resp.error 
      ? onDismiss && onDismiss()
      : (alert('There was an error dismissing this quote'), setIsDismissing(false))
  };

  const internalMessage = (
    <div className="flex-row">
      <div>This quote requires review.</div>
      <div>
        <Checkbox checked={sendEmail} onChange={onChange}>Send email update</Checkbox>
        <Button primary onClick={onClick} disabled={!!isDismissing}>{isDismissing ? <LoadingIcon /> : 'Dismiss Review Status'}</Button>
      </div>
    </div>
  );

  // NOTE: sorted list to match sorted api options
  const reviewOptions = {
    kmz: 'KMZ',
    routeDiverse: 'route diverse',
    routeDiverseSameCarrier: 'route diverse (same carrier)',
    routeProtection: 'route protection'
  }

  const optionReasons = Object.entries(reviewOptions)
    .map(([ key, value ]) => (!!requirements && requirements[key] && value))
    .filter(x => !!x).join(', ');

  // If the reasons for the present review is what was set by rate engine, then
  // show a message giving the user additional context as to why the quote
  // entered review.
  const optionsMsg = !!optionReasons 
    && requirements?.statusDetails?.toLowerCase() === optionReasons.toLowerCase() 
    && `${capitalize(optionReasons)} cannot be automated. `

  const externalMessage = (
    <>
      {!!optionsMsg && optionsMsg}
      This quote has been sent to the pricing team to be reviewed. 
      For further information, please contact:&nbsp;
      <EmailLink to="connectivitypricing@unitasglobal.com" />
    </>
  )

  return (
    <Notification.Warning className="quote-viewer__review-status-notification" children={canCompleteReview ? internalMessage : externalMessage} />
  )
}

export default withRouter(withUserContext(QuoteViewer));
