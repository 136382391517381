
import React, { useEffect, useState } from 'react';
import { SortableTable } from 'Atoms';

import { getRabbitMqQueue } from 'Actions/ccm/api.js';

const RabbitMqQueue = () => {
  const [ rowData=[], setRowData ] = useState();

  useEffect(() => {
    getRabbitMqQueue().then(resp => setRowData(resp));
  }, []);

  const COLUMN_DATA = [
    { key: 'name', header: 'Queue Name' },
    { key: 'consumers' },
    { key: 'messages_ready', header: 'Ready' },
    { key: 'messages_unacknowledged', header: 'Unacked' },
  ];

  return (
    <div className="rabbitmq-queue">
      <SortableTable className="rabbitmq-queue__table" columnData={COLUMN_DATA} rowData={rowData} />
    </div>
  )
}

export default RabbitMqQueue;